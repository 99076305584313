import React from 'react'
import './style.scss'
import '../../style.scss'
import '../../nsw-frontend-library/styles/global.scss'
import {GUTTER_SPACE} from '../../utilities/constants'
import '../../nsw-frontend-library/components/cards/cardStyles.scss'
import {Col, Row} from 'antd'
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai'
import {useLazyQuery} from '@apollo/client'
import Countdown from 'react-countdown';
import moment from 'moment'
import * as queries from './queries'
import * as defaultQueries from '../../nsw-frontend-library/queries/queries'
import {getFromLocalStorage} from '../../storage/storage'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import {useTranslation} from 'react-i18next';

const responsiveCarousel = {
    desktop: {
        breakpoint: {max: 3000, min: 1600},
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: {max: 1600, min: 1000},
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: {max: 1000, min: 0},
        items: 1,
        slidesToSlide: 1.
    }
};

const Dashboard = () => {
    const {t} = useTranslation();
    const isPro = JSON.parse(localStorage.getItem('isPro'))
    const isFirst = JSON.parse(localStorage.getItem('isFirstPageSinceLogin'))
    if (!isPro && isFirst) {
        localStorage.setItem('isFirstPageSinceLogin', false);
    }

    let [getSalesPackages, {
        data: salesPackageIds,
        loading: salesPackageIdsReqLoading
    }] = useLazyQuery(defaultQueries.GetSalesPackageIDs)
    const [getNewsFiles, {
        data: newsFiles,
        loading: newsReqLoading
    }] = useLazyQuery(defaultQueries.GetDownloadFilesOfType)
    const [getCardsData, {data: cardsData, loading: cardsInfoReqLoading}] = useLazyQuery(queries.GetCardsData, {
        skip: salesPackageIdsReqLoading,
    })

    if (!salesPackageIds && !salesPackageIdsReqLoading) {
        salesPackageIds = getFromLocalStorage('salesPackageIds');
        if (!salesPackageIds)
            getSalesPackages();
    }

    if (!newsFiles && !newsReqLoading) {
        getNewsFiles({variables: {file_type: 'news'}});
    }

    if (!cardsData && !cardsInfoReqLoading && salesPackageIds) {
        getCardsData({variables: {salespackage_ids: salesPackageIds.salesPackages?.map(x => x.id)}});
    }

    if (salesPackageIdsReqLoading || cardsInfoReqLoading || newsReqLoading) return <Loading/>
    const nfiles = newsFiles?.files ? JSON.parse(JSON.stringify(newsFiles?.files))?.sort((a, b) => a.sort_number - b.sort_number) : [];
    const data = cardsData?.me
    return (
        <div className='dashboard'>
            <Row className="ant-row" gutter={[GUTTER_SPACE, GUTTER_SPACE]}>
                <DarkCard digit={data?.is_subscription_active} text={t('dashboard.active.packagePro')}/>
                <DarkCard digit={data?.activeDirectPartnerPro} text={t('dashboard.active.directPros')}/>
                <DarkCard digit={data?.activeIndirectPartnerPro} text={t('dashboard.active.indirectPros')}/>
                <DarkCard digit={data?.activeTotalPartnerPro} text={t('dashboard.active.pros')}/>
            </Row>
            <Row className="ant-row" gutter={[GUTTER_SPACE, GUTTER_SPACE]}>
                <LightCard title={t('dashboard.network.all.title')}>
                    <div className='list-items'>
                        <div>{t('dashboard.network.direct')}</div>
                        <div>{data?.directPartner}</div>
                    </div>
                    <div className='list-items'>
                        <div>{t('dashboard.network.indirect')}</div>
                        <div>{data?.indirectPartner}</div>
                        {/*<div>{data?.totalPartner - data?.directPartner}</div>*/}
                    </div>
                    <div className='list-items'>
                        <div>{t('dashboard.network.total')}</div>
                        <div>{data?.totalPartner}</div>
                    </div>
                </LightCard>
                <LightCard title={t('dashboard.network.pro.title')}>
                    <div className='list-items'>
                        <div>{t('dashboard.network.direct')}</div>
                        {
                            checkNumber(data?.activeDirectPartnerPro, 5)
                        }
                    </div>
                    <div className='list-items'>
                        <div>{t('dashboard.network.indirect')}</div>
                        {
                            checkNumber(data?.activeIndirectPartnerPro, 5)
                        }
                    </div>
                    <div className='list-items'>
                        <div>{t('dashboard.network.total')}</div>
                        {
                            checkNumber(data?.activeTotalPartnerPro, 50)
                        }
                    </div>
                </LightCard>
                <LightCard title={t('dashboard.pool.title')}>
                    <div className='list-items'>
                        <div>{t('dashboard.pool.stars')}</div>
                        <div>0</div>
                    </div>
                    <div className='list-items'>
                        <div>{t('dashboard.pool.elligible')}</div>
                        <div>0</div>
                    </div>
                </LightCard>
            </Row>
            <Row className="ant-row" gutter={[GUTTER_SPACE, GUTTER_SPACE]}>

            <div className='darkCard dashboard-darkCard' style={{marginLeft: '8px', marginRight: '8px', width: '100%'}}>
                <Countdown date={moment().endOf('month')}
                           renderer={({days, hours, minutes}) => {
                               return (
                                   <span>{t('dashboard.countdown.untilEnd')}{days} {t('dashboard.countdown.days')} {hours} {t('dashboard.countdown.hours')} {minutes} {t('dashboard.countdown.minutes')} </span>);
                           }}/>
            </div>
            </Row>
                <div className='lightCard sliderContainer cardMargin'>
                <div className='lightCard-title'>{t('dashboard.news')}</div>
                {
                    !newsFiles ? <br/> : <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsiveCarousel}
                        infinite={true}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        transitionDuration={500}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {nfiles?.map(rec => {
                            return <div className='dashboard-slider-item'>
                                <img src={rec.url} alt=""/>
                            </div>
                        })}
                    </Carousel>
                }
            </div>
        </div>
    )
}
const checkNumber = (variable, constant) => {
    return (
        <li className='checkNumberDiv'>
            <div>{variable} / {constant} </div>
            <div>
                {
                    variable >= constant ? <div className='check'>< AiOutlineCheck/></div> :
                        <div className='close'><AiOutlineClose/></div>
                }
            </div>
        </li>
    )
}
const DarkCard = (props) => {
    const {digit, text} = props
    return (
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 6}} xl={{span: 6}}>
            <div className='darkCard dashboard-darkCard'>
                {
                    digit === true ? (
                        <div className='dashboard-darkCard-item check'>< AiOutlineCheck/></div>
                    ) : digit === false ? (
                        <div className='dashboard-darkCard-item close'><AiOutlineClose/></div>
                    ) : (
                        <div className='dashboard-darkCard-digit'>{digit}</div>
                    )
                }
                <div className='dashboard-darkCard-text'>{text}</div>

            </div>
        </Col>
    )
}
const LightCard = (props) => {
    const {children, title} = props
    return (
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 12}} xl={{span: 8}}>
            <div className='lightCard dashboard-lightCard'>
                <div className='lightCard-title'>{title}</div>
                <div className='lightCard-divider'/>
                <div className='lightCard-content'>
                    {children}
                </div>
            </div>
        </Col>
    )
}
export default Dashboard
