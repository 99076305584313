import React, {useState} from "react";
import {comparePassword, isValidPassword, maxLength100, trimInput} from "../../../utils/validations";
import {
    ValidationInputField,
    validation
} from "../../fields/validations";
import * as queries from "./queries";
import {useApolloClient} from "@apollo/client";
import {Col, Row} from "antd";
import '../cardStyles.scss';
import '../../../styles/fields.scss';
import {throwInternError} from '../../../utils/errorHandler';
import {showSuccessfulMessage, showErrorMessage} from "../../../utils/messageHandler";
const _ = require('lodash');

const defaultInputValue = {
    currentPassword: {
        value: '',
        valid: false,
        messages: [],
        callbackVariable: 'currentPassword',
        validations: [maxLength100]
    },
    newPassword: {
        value: '',
        valid: false,
        messages: [],
        callbackVariable: 'newPassword',
        validations: [maxLength100, isValidPassword]
    },
    confirmNewPassword: {
        value: '',
        valid: false,
        messages: [],
        callbackVariable: 'confirmNewPassword',
        validations: [maxLength100, isValidPassword]
    }
};

export const ChangePassword = () => {
    const client = useApolloClient();
    const [inputValues, setInputValue] = useState(_.cloneDeep(defaultInputValue))

    const changeValue = (val, id, valid, messages, overwriteMessages = true) => {
        let newInputValue = _.clone(inputValues);
        newInputValue[id].value = trimInput(val);

        if(overwriteMessages || (newInputValue[id].valid === true && !newInputValue[id].messages?.length > 0)){
            messages !== null && messages !== undefined && (newInputValue[id].messages = messages);
        }
        valid !== null && valid !== undefined && (newInputValue[id].valid = valid);

        setInputValue(newInputValue);
    }

    const resetPassword = async () => {
        let fieldsValid = true;
        for (const v of Object.values(inputValues)) {
            if (v.hasOwnProperty('valid') && !v.valid) {
                fieldsValid = false;
                break;
            }
        }

        if (!fieldsValid) {
            for (let field in inputValues) {
                validation(inputValues[field], {callback: changeValue});
            }
            additionalValidation()
            return;
        }

        if(!additionalValidation()){
            return;
        }

        function additionalValidation(){
            const compareResult = comparePassword(inputValues.newPassword.value, inputValues.confirmNewPassword.value);
            if(!compareResult?.valid){
                changeValue(inputValues.confirmNewPassword.value, inputValues.confirmNewPassword.callbackVariable, compareResult.valid, [compareResult.errorMessage], false);
                return false;
            }
            return true;
        }

        try {
            const {data} = await client.query({query: queries.resetPassword(inputValues)});
            data?.resetPassword?.result === true ?
                showSuccessfulMessage() :
                showErrorMessage(data?.resetPassword?.error || 'Cannot change password');

            document.getElementById('change-password-old-input').value = '';
            document.getElementById('change-password-new-input').value = '';
            document.getElementById('change-password-new-confirmation-input').value = '';

            setInputValue({
                ...inputValues,
                ..._.cloneDeep(defaultInputValue)
            });
        } catch (e) {
            throwInternError(e);
        }
    }

    return <Row>
        <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
            <label className='lightCard-inputLabel'>Current password</label>
        </Col>
        <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
            <ValidationInputField callback={changeValue}
                                  styles='lightCard-input-field'
                                  inputValue={inputValues?.currentPassword}
                                  inputType='password' id='change-password-old'/>
        </Col>
        <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
            <label className='lightCard-inputLabel'>New password</label>
        </Col>
        <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
            <ValidationInputField callback={changeValue}
                                  styles='lightCard-input-field'
                                  inputValue={inputValues?.newPassword}
                                  inputType='password' id='change-password-new'
            />
        </Col>
        <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
            <label className='lightCard-inputLabel'>Confirm new password</label>
        </Col>
        <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
            <ValidationInputField callback={changeValue}
                                  styles='lightCard-input-field'
                                  inputValue={inputValues?.confirmNewPassword}
                                  inputType='password' id='change-password-new-confirmation'
            />
        </Col>
        <Col xs={{span: 12}} sm={{span: 12}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
            <button
                className='lightCard-button' onClick={async () => {
                await resetPassword()
            }}>Change Password</button>
        </Col>
    </Row>;
}
