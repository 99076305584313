import React, {useState} from 'react'
import './style.scss'
import '../../nsw-frontend-library/styles/global.scss'
import '../../nsw-frontend-library/components/cards/cardStyles.scss'
import {VscFilePdf} from 'react-icons/all'
import {Input, Table, Button, notification} from 'antd'
import {useLazyQuery} from '@apollo/client'
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import * as queries from './queries'
import {search} from "ss-search"
import {useTranslation} from "react-i18next";

function addTableColumn(columns, columnKey, columnName, filterValues, onFilter) {
    columns.push({
        title: columnName.toUpperCase(),
        dataIndex: columnKey,
        filters: filterValues,
        onFilter: onFilter
    })
}

const Invoices = () => {
    const {t} = useTranslation();
    const [getSalesDeals, {
        data: salesDealsInfo,
        loading: salesDealsInfoReqLoading
    }] = useLazyQuery(queries.getSalesDealsQuery)
    const [getNecessaryInvoiceData, {
        data: necessaryInvoiceData,
        loading: necessaryInvoiceDataLoading
    }] = useLazyQuery(queries.checkNecessaryInvoiceData, {skip: salesDealsInfoReqLoading})
    const [filterText, setFilterText] = useState();

    if (!salesDealsInfo && !salesDealsInfoReqLoading) {
        getSalesDeals();
    }

    if (!necessaryInvoiceData && !necessaryInvoiceDataLoading) {
        getNecessaryInvoiceData();
    }

    if (salesDealsInfoReqLoading || necessaryInvoiceDataLoading) return <Loading/>

    if(necessaryInvoiceData && salesDealsInfo && !necessaryInvoiceDataLoading && !salesDealsInfoReqLoading){
        for(const v of Object.values(necessaryInvoiceData.me)){
            if(!v){
                notification.open({
                    message: 'Missing profile information',
                    description:
                        'Please add missing required fields.',
                    btn: (
                        <Button type="primary" size="small" onClick={() => window.location.href = '/backoffice/edit-profile'}>
                            Go to my profile
                        </Button>
                    ),
                    duration: 0
                });
                break;
            }
        }
    }

    let deposits = salesDealsInfo?.me?.deposits ? JSON.parse(JSON.stringify(salesDealsInfo?.me?.deposits)) : [];
    let sales = []
    deposits.forEach(a => {
        const mt = a?.sale?.salesPackage?.name + " - " + a?.sale?.subscriptionPackage?.num_months
        const il = <a href={"/backend/invoice/" + a?.sale?.id}><VscFilePdf className='invoices-pdf-icon'/></a>
        a = a?.sale;
        a.monthText = mt;
        a.invoiceLink = il;
        if (a?.fullpaid_at) {
            sales.push(a)
        }
    });

    const searchKeys = ["order_number", "invoice_number", "monthText", "amount", "fullpaid_at"];
    const filteredSales = search(sales, searchKeys, filterText);

    let columns = [];
    addTableColumn(columns, 'order_number', t('invoices.table.orderNumber'));
    addTableColumn(columns, 'invoice_number', t('invoices.table.invoiceNumber'));
    addTableColumn(columns, 'monthText', t('invoices.table.month'));
    addTableColumn(columns, 'amount', t('invoices.table.amount'));
    addTableColumn(columns, 'fullpaid_at', t('invoices.table.date'));
    addTableColumn(columns, 'invoiceLink', t('invoices.table.pdf'));

    return (
        <div className='invoices'>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className='lightCard filterCard'>
                    <Input id='commissionFilter' placeholder={t('invoices.search')}
                           onChange={e => {
                               setFilterText(e.target.value);
                           }}
                    />
                </div>
            </div>
            <div className='lightTable'>
                <Table columns={columns} dataSource={filteredSales} pagination={{defaultPageSize: 15}}/>
            </div>
        </div>
    )
}
export default Invoices
