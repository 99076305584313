import React from 'react'
import './style.scss'
import {Row, Col} from 'antd'

const ContentUserLayout = ({children}) => {
    return (
        <div className='contentUserLayout'>
            <Row justify='center' style={{height: '100%'}}>
                <Col xs={22} sm={22} md={22} lg={22} xl={20}>
                    {children}
                </Col>
            </Row>
        </div>
    )
}

export default ContentUserLayout
