import {gql} from "@apollo/client";

export const getTimeRangesQuery = gql`
query {
  me {
    active_timeranges{
      id
      name
    }
  }
}
`
export const GetSalesPackageIDs = gql`
query{
  salesPackages{
    id
  }
}
`
export const GetLanguages = gql`
query {
  languages{
    id,
    name
  }
}
`
export const GetDownloadFilesOfType = gql`
query($file_type: String!){
    files(file_type: $file_type){
        id
        title
        content
        url
        file_name
        file_type
        sort_number
    }
}
`

export const GetCurrentSalesPackage = () => gql`
query {
  me{
    currentSalesPackage {
        name
    }
  }
}
`
