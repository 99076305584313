import React from "react";
import './style.scss';
import {throwInternError} from '../../../utils/errorHandler'
import default_photo from '../../../assets/photo_placeholder.jpg';

export const ProfilePictureUploader = (props) => {
    const {callbackOnSuccess, callbackOnFail, profilePictureUrl = null} = props;

    const changeProfilePicture = async (val) => {
        val.preventDefault()
        const reader = new FileReader()
        const file = val.target.files[0];
        reader.addEventListener('load', async (event) => {
            try {
                const file_binary = event.target.result;
                await fetch('/backend/upload-user-file/profile-picture', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        file_name: file.name,
                        file_size: file.size,
                        file_binary,
                        mime_type: file.type
                    })
                });
                document.getElementById('profile-picture-uploader-image').src = '/backend/user-file/profile-picture?time=' + new Date().getTime();
                callbackOnSuccess && callbackOnSuccess();
            } catch (e) {
                throwInternError(e);
                callbackOnFail && callbackOnFail(e);
            }
        })
        await reader.readAsBinaryString(file);
    }

    return <div className='profile-picture-uploader-div'>
        <img id='profile-picture-uploader-image' className='profile-picture-uploader-image'
            src={profilePictureUrl ? '/backend/partner-file/' + profilePictureUrl : default_photo} alt=""
        />
        <div className='profile-picture-uploader-label'>
            <label htmlFor='profile-picture-input'>Upload Image</label>
            <input id='profile-picture-input' type="file" onChange={e => changeProfilePicture(e)}/>
        </div>
    </div>;
}
