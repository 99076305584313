// eslint-disable-next-line
const emailRegex = /(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export function isValidMail(mail){
    const valid = emailRegex.test(mail);
    return {valid, errorMessage: !valid ? 'Invalid email' : null};
}

export function maxLength100(text){
    const valid = text.length < 100;
    return {valid, errorMessage: !valid ? 'Maximum 100 characters' : null};
}

export function isValidPassword(password){
    const PASSWORD_MIN_LEN = 6

    if(password.length < PASSWORD_MIN_LEN){
        return {valid: false, errorMessage: 'Minimum length of password is ' + PASSWORD_MIN_LEN}
    }

    return {valid: true, errorMessage: null}
}

export function comparePassword(password, password2){
    if(password !== password2){
        return {valid: false, errorMessage: 'Passwords do not match'}
    }
    return {valid: true, errorMessage: null}
}

export function trimInput(val){
    return typeof(val) === 'string' ? val.trim() : val;
}
