import React from 'react'
import './style.scss'
import '../../nsw-frontend-library/styles/global.scss'
import '../../nsw-frontend-library/components/cards/cardStyles.scss'
import {Col, Row} from 'antd'
import {FaPhoneAlt, GrMail, AiFillCustomerService, BsFillAwardFill} from 'react-icons/all'

const Support = () => {
    return (
        <div className='page support'>
            <Row gutter={[16, 16]}>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                    <div className='lightCard supportCard'>
                        <div className='supportCard-title'>
                            <AiFillCustomerService/>
                            <div>Support</div>
                        </div>
                        <div className='supportCard-sec'>
                            <FaPhoneAlt/>
                            <div>0937910656</div>
                        </div>
                        <div className='supportCard-sec'>
                            <GrMail/>
                            <div>support@invitepro.com</div>
                        </div>
                        <div className='supportCard-text'>if you have any questions, feedback or suggestions for
                            improvment, please contact us.
                        </div>
                    </div>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 12}}>
                    <div className='lightCard supportCard'>
                        <div className='supportCard-title'>
                            <BsFillAwardFill/>
                            <div>Social Bonus</div>
                        </div>
                        <div className='bonusText'>Donate automatically with each event</div>
                        <div>
                            € 5.01 <br/>
                            Total donated bonus
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Support
