import React, {useState} from 'react'
import './style.scss'
import '../../nsw-frontend-library/styles/global.scss'
import '../../nsw-frontend-library/components/cards/cardStyles.scss'
import '../../nsw-frontend-library/styles/table.scss'
import {Input, Table} from 'antd'
import {useLazyQuery} from '@apollo/client'
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import * as queries from './queries'
import * as defaultQueries from '../../nsw-frontend-library/queries/queries'
import {search} from "ss-search";
import {useTranslation} from 'react-i18next';

function addTableColumn(columns, columnKey, columnName, filterValues, onFilter) {
    columns.push({
        title: columnName,
        dataIndex: columnKey,
        filters: filterValues,
        onFilter: onFilter
    })
}


const Team = () => {
    let rowIndex = 1;
    const {t} = useTranslation();
    const [filterText, setFilterText] = useState();
    const [getSalesPackages, {
        data: salesPackageIds,
        loading: salesPackageIdsReqLoading
    }] = useLazyQuery(defaultQueries.GetSalesPackageIDs)
    const [getTeamInfo, {data: teamInfo, loading: teamInfoReqLoading}] = useLazyQuery(queries.GetTeamInfo, {
        skip: salesPackageIdsReqLoading,
    })

    if (!salesPackageIds && !salesPackageIdsReqLoading) {
        getSalesPackages();
    }

    if (!teamInfo && !teamInfoReqLoading && salesPackageIds) {
        getTeamInfo({variables: {salespackage_ids: salesPackageIds.salesPackages?.map(x => x.id)}});
    }

    if (teamInfoReqLoading || salesPackageIdsReqLoading) return <Loading/>

    let downline = teamInfo?.me?.downline ? JSON.parse(JSON.stringify(teamInfo?.me?.downline)) : [];
    downline.forEach(a => {
        a.index = rowIndex++;
        a.current_sales_package_name = a.currentSalesPackage.name
        a.status = a.is_subscription_active ? <div className='check'>Active</div> :
            <div className='close'>Inactive</div>
    });

    const searchKeys = ["user_firstname", "user_lastname", "mlm_level", "user_email", "user_phone", "created_at", "current_sales_package_name"];
    const filteredDownline = search(downline, searchKeys, filterText);

    let columns = []
    const levelList = filteredDownline.map(a => a.mlm_level).filter((value, index, self) => self.indexOf(value) === index).map(value => ({
        text: 'Level ' + value,
        value
    }))
    const registeredList = filteredDownline.map(a => a.created_at).filter((value, index, self) => self.indexOf(value) === index).map(value => ({
        text: value,
        value
    }))
    addTableColumn(columns, 'index', '#');
    addTableColumn(columns, 'id', 'ID');
    addTableColumn(columns, 'user_firstname', t('team.table.firstname'));
    addTableColumn(columns, 'user_lastname', t('team.table.lastname'));
    addTableColumn(columns, 'mlm_level', t('team.table.level'), levelList, (value, row) => {
        return row['mlm_level'] === value;
    });
    addTableColumn(columns, 'user_email', t('team.table.email'));
    addTableColumn(columns, 'user_phone', t('team.table.phone'));
    addTableColumn(columns, 'created_at', t('team.table.registered'), registeredList, (value, row) => {
        return row['created_at'] === value;
    });
    addTableColumn(columns, 'direct_partner', t('team.table.direct'));
    addTableColumn(columns, 'indirect_partner', t('team.table.indirect'));
    addTableColumn(columns, 'current_sales_package_name', t('team.table.package'));
    addTableColumn(columns, 'status', t('team.table.status'));

    return (
        <div className='team'>
            <div className='lightCard team-filter cardMargin'>
                <div className='lightCard-filter'>
                    <div className='lightCard-filter-text'>{t('team.search')}</div>
                    <Input id='teamFilter' className='lightCard-inputTextBox' placeholder={t('team.search').toLowerCase()+'...'}
                           onChange={e => {
                               setFilterText(e.target.value);
                           }}
                    />
                </div>
            </div>
            <div className='lightTable'>
                <Table columns={columns} dataSource={filteredDownline} pagination={{defaultPageSize: 15}}/>
            </div>
        </div>
    )
}
export default Team
