import {Checkbox, Select} from "antd";
import React from "react";

import "./style.scss";

export const ValidationCheckbox = (props) => {
    const {
        inputValue,
        callback,
        validCallback = callback,
        invalidCallback = callback,
        styles,
        isDefaultChecked = true,
        checkboxText = null,
        id
    } = props;

    const {
        valid
    } = inputValue;

    let validationTimer = 0;

    return <div id={id}
                className={styles + (valid ? ' input-validation-checkbox-valid' : ' input-validation-checkbox-invalid')}>
        <Checkbox id={id + '-checkbox'}
                  onChange={e => validationTimer = validationWithTimer(inputValue, 0, validationTimer, {
                      validCallback,
                      invalidCallback,
                      newValue: !!e.target.checked
                  })}
                  type='checkbox' defaultChecked={isDefaultChecked}>{checkboxText}</Checkbox>
    </div>
}

export const ValidationInputField = (props) => {
    const {
        inputValue,
        callback,
        validCallback = callback,
        invalidCallback = callback,
        styles,
        placeholder,
        inputType = 'text',
        id
    } = props;

    const {
        value,
        valid,
        messages
    } = inputValue;

    let validationTimer = 0;

    function validateValue(val, timeout) {
        validationTimer = validationWithTimer(inputValue, timeout, validationTimer, {
            validCallback,
            invalidCallback,
            newValue: val
        })
    }

    return <div id={id}>
        <input id={id + '-input'} onChange={(e) => {
            validateValue(e.target.value, 1000);
        }}
               onBlur={(e) => {
                   validateValue(e.target.value, 0)
               }}
               placeholder={placeholder} type={inputType}
               className={styles + (valid || messages?.length === 0 ? '' : ' input-validation-field-invalid')}
               defaultValue={value}/>
        <label
            id={id + '-label'}
            className={valid ? 'input-validation-field-label-valid' : 'input-validation-field-label-invalid'}>{messages?.length > 0 ? ('*' + messages[0]) : null}</label>
    </div>
}

export const ValidationSelect = (props) => {
    const {
        inputValue,
        callback,
        validCallback = callback,
        invalidCallback = callback,
        styles,
        options,
        placeholder,
        id
    } = props;

    const {
        valid,
        messages
    } = inputValue;

    let validationTimer = 0;

    return <div id={id} className={styles}>
        <Select id={id + '-select'} placeholder={placeholder}
                onChange={(e) => {
                    validationTimer = validationWithTimer(inputValue, 0, validationTimer, {
                        validCallback,
                        invalidCallback,
                        newValue: e.split('_')[1]
                    })
                }}>
            {options}
        </Select>
        <label
            id={id + '-label'}
            className={valid ? 'input-validation-field-label-valid' : 'input-validation-field-label-invalid'}>{messages?.length > 0 ? ('*' + messages[0]) : null}</label>
    </div>
}

export function validationWithTimer(inputValue, timeout, validationTimer, {
    callback,
    validCallback = callback,
    invalidCallback = callback,
    newValue = null
}) {
    if (validationTimer) {
        clearTimeout(validationTimer);
    }

    return setTimeout(function () {
        validation(inputValue, {
            validCallback, invalidCallback, newValue
        })
    }, timeout)
}

export function validation(inputValue, {
    callback,
    validCallback = callback,
    invalidCallback = callback,
    newValue = null
}) {
    const {value, callbackVariable, validations, isMandatory = true} = inputValue;
    const val = newValue ?? value;

    let messages = [];
    let isValid = true;

    if (!val) {
        if (isMandatory === true) {
            isValid = false;
            messages.push('Mandatory field');
        }
    } else {
        for (let validation of validations) {
            const validationResult = validation(val);
            if (!validationResult.valid) {
                isValid = false;
                messages.push(validationResult.errorMessage);
            }
        }
    }

    isValid && validCallback && validCallback(val, callbackVariable, isValid, messages);
    !isValid && invalidCallback && invalidCallback(val, callbackVariable, isValid, messages);
}