import React, {useEffect, useState} from 'react'
import './style.scss'
import {Row, Col} from 'antd'
import {useLocation} from "react-router-dom"
import {useLazyQuery, useApolloClient} from '@apollo/client'
import {MenuOutlined} from '@ant-design/icons'
import * as queries from './queries'
import * as defaultQueries from '../../nsw-frontend-library/queries/queries'
import {AiFillStar, AiOutlineClose, AiOutlineCheck} from "react-icons/ai";
import {IconContext} from "react-icons";
import {GUTTER_SPACE} from '../../utilities/constants'
import {LanguageSelector, Logout} from '../../nsw-frontend-library/components/buttons'
import {useTranslation} from "react-i18next";

const Header = props => {
    const {t} = useTranslation();
    let location = useLocation();
    const client = useApolloClient();
    const [hamStatus, setHamStatus] = useState(false)
    useEffect(() => {
        checkBroWidth(window.innerWidth)
        window.addEventListener('resize', () => checkBroWidth(window.innerWidth))
    }, [])
    const checkBroWidth = (width) => {
        if (width > 600) setHamStatus(false)
        else setHamStatus(true)
    };

    const doLogOut = async () => {
        let tries = 10;
        do {
            const {data} = await client.query({query: queries.LogOut, fetchPolicy: "no-cache"});
            if (data?.logout?.result === true) {
                localStorage.removeItem('login');
                localStorage.removeItem('isPro');
                localStorage.removeItem('isFirstPageSinceLogin');
                window.location.href = '/backoffice/auth/login';
                break;
            }
        } while (tries--);
    }

    const [getSalesPackages, {
        data: salesPackageIds,
        loading: salesPackageIdsReqLoading
    }] = useLazyQuery(defaultQueries.GetSalesPackageIDs)
    const [getCardsData, {data: cardsData, loading: cardsInfoReqLoading}] = useLazyQuery(queries.GetHeaderInfo, {
        skip: salesPackageIdsReqLoading,
    })

    if (!salesPackageIds && !salesPackageIdsReqLoading) {
        getSalesPackages();
    }

    if (!cardsData && !cardsInfoReqLoading && salesPackageIds) {
        getCardsData({variables: {salespackage_ids: salesPackageIds.salesPackages?.map(x => x.id)}});
    }

    if (cardsInfoReqLoading) return null
    const data = cardsData?.me
    if (data) {
        localStorage.setItem('isPro', data?.currentSalesPackage?.name === 'Professional');
    }
    return (
        <div>

        <div className='header'>
            <Row className='column' gutter={[GUTTER_SPACE, GUTTER_SPACE]}>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 16}} lg={{span: 16}} xl={{span: 12}}>
                    <div className='title-sec'>
                        {
                            hamStatus === true ? (
                                <MenuOutlined className='title-icon' onClick={props.toggleDrawer}/>
                            ) : ''
                        }
                        <div className='title-text'>{location?.state?.pageName || 'Dashboard'}</div>
                    </div>

                </Col>
                <Col xs={{span: 6}} sm={{span: 6}} md={{span: 4}} lg={{span: 4}} xl={{span: 2}}>
                    <div className='lightCard headerLightCard'>
                        <IconContext.Provider
                            value={{color: 'gold', size: '1.45em'}}>
                            <AiFillStar/>
                        </IconContext.Provider>
                        0
                    </div>
                </Col>
                <Col xs={{span: 0}} sm={{span: 0}} md={{span: 0}} lg={{span: 0}} xl={{span: 8}}>
                    <div className='lightCard headerLightCard proAmountCard'>
                        <div className='directIndirectPartner'>{t('header.direct')}{checkNumber(data?.activeDirectPartnerPro, 5)}</div>
                        <div className='lightCard-verticalDivider'/>
                        <div className='directIndirectPartner'>{t('header.indirect')}{checkNumber(data?.activeIndirectPartnerPro, 5)}</div>
                    </div>
                </Col>
                {/*<Col xs={{span: 6}} sm={{span: 6}} md={{span: 4}} lg={{span: 4}} xl={{span: 2}}>*/}
                <Col xs={{span: 6}} sm={{span: 6}} md={{span: 4}} lg={{span: 4}} xl={{span: 2}}>
                    <div className='lightCard headerLightCard exit-sec headerButton'>
                        <Logout logoutCallback={doLogOut}/>
                    </div>
                    <div className='lightCard headerLightCard headerButton'>
                        <LanguageSelector />
                    </div>
                </Col>
            </Row>
        </div>
        </div>
)
}

const checkNumber = (variable, constant) => {
    return (
        <div className='checkNumberDiv'>
            <div>{variable}</div>
            <div>{variable >= constant ? <div className='check'>< AiOutlineCheck/></div> :
                <div className='close'><AiOutlineClose/></div>}</div>
        </div>
    )
}

export default Header
