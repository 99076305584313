import React, {useEffect, useState} from 'react'
import './style.scss'
import '../../style.scss'
import '../../nsw-frontend-library/components/cards/cardStyles.scss'
import '../../nsw-frontend-library/styles/fields.scss'
import {Row, Col, Select, Input, Checkbox} from 'antd'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useQuery, useMutation, useApolloClient} from '@apollo/client'
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import * as queries from './queries'
import * as defaultQueries from '../../nsw-frontend-library/queries/queries'
import * as $ from 'jquery'
import moment from 'moment'
import {GUTTER_SPACE} from '../../utilities/constants'
import {getCountryList} from "../../storage/storage";
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'
import {ChangePassword} from "../../nsw-frontend-library/components/cards/changePassword";
import {ProfilePictureUploader} from "../../nsw-frontend-library/components/cards/profilePictureUploader";
import {trimInput} from "../../nsw-frontend-library/utils/validations";

function isValidMail(mail) {
    // eslint-disable-next-line no-control-regex
    const emailRegex = /(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return emailRegex.test(mail);
}

function validateFormData(data) {
    const INPUT_MAX_LEN = 100;
    const result = {success: true, missing: []};
    const required_fields = ['gender_id', 'user_phone', 'user_email', 'user_country_id', 'user_address', 'user_zipcode', 'user_city'];
    for (const [k, v] of Object.entries(data))
        if (required_fields.includes(k) &&
            (!v || v === '' ||
            v.length > INPUT_MAX_LEN ||
            (k === 'user_email' && !isValidMail(v)))) {
            result.success = false;
            result.missing.push(k);
        }
    return result;
}

const EditProfile = () => {
    const {t} = useTranslation();
    const client = useApolloClient();
    const [updateData, setUpdateData] = useState({
        user_firstname: ''
    })
    const [countries, setCountries] = useState([])
    const [startDate, setStartDate] = useState()
    const changeValue = async (val, id) => {
        setUpdateData({
            ...updateData,
            [id]: trimInput(val)
        })
    }

    const {data: profileInfo, loading: profileInfoReqLoading} = useQuery(queries.GetUserProfileInfo, {
        onCompleted: d => {
            console.log(d);
            setUpdateData({
                ...updateData,
                user_firstname: d?.me?.user_firstname
            })
        }
    })


    useEffect(() => {
        async function countryList() {
            setCountries(await getCountryList(client));
        }

        countryList();
        // eslint-disable-next-line
    }, []);

    const {data: languageInfo, loading: languagesInfoReqLoading} = useQuery(defaultQueries.GetLanguages)

    const [updatePro] = useMutation(queries.updateProfile(updateData), {
        onCompleted: d => {
            if (d?.updateProfile === true) {
                Swal.fire({icon: 'success', title: 'Done', text: 'Profile changes have been saved.', confirmButtonColor: '#FFA500'})
            }
        }
    })

    const profileUploaderSuccessCallback = () => {
        document.getElementById('profilePictureMenu').src = '/backend/user-file/profile-picture?time=' + new Date().getTime();
    }

    if (profileInfoReqLoading || languagesInfoReqLoading) return <Loading/>
    const data = profileInfo?.me
    let birthday = data?.user_birthday ? moment(data.user_birthday, 'DD/MM/yyyy').toDate() : new Date()
    const languages = languageInfo?.languages;

    return (
        <div className='editProfile'>
            <Row gutter={[GUTTER_SPACE, GUTTER_SPACE]}>
                <Col xs={{span: 24, order: 1}} sm={{span: 24, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}} xl={{span: 8, order: 1}}>
                    <div className='lightCard editProfileLightCard'>
                        <div className='lightCard-title'>{t('profile.masterData.headline')}</div>
                        <Row>
                            <Col xs={{span: 12, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}}>
                                <div className='lightCard-inputLabel' htmlFor='academicTitle'>{t('profile.masterData.title')}</div>
                                <div className='lightCard-inputLabel' htmlFor='user_firstname'>{t('profile.masterData.firstName')}</div>
                                <div className='lightCard-inputLabel' htmlFor='user_lastname'>{t('profile.masterData.lastName')}</div>
                                <div className='lightCard-inputLabel' htmlFor='Birthday'>{t('profile.masterData.birthday')}</div>
                                <div className='lightCard-inputLabel' htmlFor='language'>{t('profile.masterData.language')}</div>
                                <div className='lightCard-inputLabel' htmlFor='Company'>{t('profile.masterData.company')}</div>
                                <div className='lightCard-inputLabel' htmlFor='TaxorUID-No'>{t('profile.masterData.taxNumber')}</div>
                            </Col>
                            <Col xs={{span: 12, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}}>
                                <Input className='lightCard-inputTextBox' id='academicTitle' defaultValue={data?.user_title}
                                       onChange={(e) => changeValue(e.target.value, 'user_title')}/>
                                <Input className='lightCard-inputTextBox' id='user_firstname' defaultValue={data?.user_firstname} title={data?.user_firstname}
                                       onChange={(e) => changeValue(e.target.value, 'user_firstname')}/>
                                <Input className='lightCard-inputTextBox' id='user_lastname' defaultValue={data?.user_lastname} title={data?.user_lastname}
                                       onChange={(e) => changeValue(e.target.value, 'user_lastname')}/>
                                <DatePicker className='lightCard-inputTextBox' id='Birthday' dateFormat='dd/MM/yyyy'
                                            placeholderText=' Enter birthday here.' selected={startDate || birthday}
                                            onChange={(e) => {
                                                changeValue(moment(e).format('DD/MM/yyyy'), 'user_birthday')
                                                setStartDate(e);
                                            }}/>
                                <Select className='lightCard-inputTextBox' id='language'
                                        defaultValue={data?.language?.id || languages[0].id}
                                        onChange={(e) => changeValue(e, 'locallanguage_id')}>
                                    {languages?.map(rec => <option key={rec.id}>{rec.name}</option>)}
                                </Select>
                                <Input className='lightCard-inputTextBox' id='Company' defaultValue={data?.company_name} title={data?.company_name}
                                       onChange={(e) => changeValue(e.target.value, 'company_name')}/>
                                <Input className='lightCard-inputTextBox' id='TaxorUID-No' defaultValue={data?.user_uid}
                                       onChange={(e) => changeValue(e.target.value, 'user_uid')}/>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={{span: 24, order: 2}} sm={{span: 24, order: 2}} md={{span: 12, order: 2}} lg={{span: 12, order: 2}} xl={{span: 8, order: 2}}>
                    <div className='lightCard editProfileLightCard'>
                        <div className='lightCard-title'>{t('profile.profile.headline')}</div>
                        <ProfilePictureUploader callbackOnSuccess={profileUploaderSuccessCallback} profilePictureUrl={data?.profile_picture_url}/>
                        <div className='lightCard-inputLabel'>
                            {t('profile.profile.createdOn')}{data?.created_at}
                        </div>
                    </div>
                </Col>
                <Col xs={{span: 24, order: 3}} sm={{span: 24, order: 3}} md={{span: 12, order: 3}} lg={{span: 12, order: 3}} xl={{span: 8, order: 3}}>
                    <div className='lightCard editProfileLightCard'>
                        <div className='lightCard-title'>{t('profile.details.contact.headline')}</div>
                        <Row>
                            <Col xs={{span: 12, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}}>
                                <div className='lightCard-inputLabel' htmlFor='cellphone'>{t('profile.details.contact.phone')}</div>
                                <div className='lightCard-inputLabel' htmlFor='user_email'>{t('profile.details.contact.email')}</div>
                            </Col>
                            <Col xs={{span: 12, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}}>
                                <Input className='lightCard-inputTextBox' id='cellphone' defaultValue={data?.user_phone} title={data?.user_phone}
                                       onChange={(e) => changeValue(e.target.value, 'user_phone')}/>
                                <Input className='lightCard-inputTextBox' id='user_email' defaultValue={data?.user_email} title={data?.user_email}
                                       onChange={(e) => changeValue(e.target.value, 'user_email')}/>
                            </Col>
                        </Row>
                        <div className='lightCard-title'>{t('profile.details.bank.headline')}</div>
                        <Row>
                            <Col xs={{span: 12, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}}>
                                <div className='lightCard-inputLabel' htmlFor='bankName'>{t('profile.details.bank.bankName')}</div>
                                <div className='lightCard-inputLabel' htmlFor='accountHolder'>{t('profile.details.bank.owner')}</div>
                                <div className='lightCard-inputLabel' htmlFor='IBAN'>{t('profile.details.bank.iban')}</div>
                                <div className='lightCard-inputLabel' htmlFor='BIC'>{t('profile.details.bank.bic')}</div>
                            </Col>
                            <Col xs={{span: 12, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}}>
                                <Input className='lightCard-inputTextBox' id='bankName' defaultValue={data?.user_bank_name}
                                       onChange={(e) => changeValue(e.target.value, 'user_bank_name')}/>
                                <Input className='lightCard-inputTextBox' id='accountHolder' defaultValue={data?.user_account_holder}
                                       onChange={(e) => changeValue(e.target.value, 'user_account_holder')}/>
                                <Input className='lightCard-inputTextBox' id='IBAN' defaultValue={data?.user_iban}
                                       onChange={(e) => changeValue(e.target.value, 'user_iban')}/>
                                <Input className='lightCard-inputTextBox' id='BIC' defaultValue={data?.user_bic}
                                       onChange={(e) => changeValue(e.target.value, 'user_bic')}/>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={{span: 24, order: 4}} sm={{span: 24, order: 4}} md={{span: 12, order: 4}} lg={{span: 12, order: 4}} xl={{span: 8, order: 4}}>
                    <div className='lightCard editProfileLightCard'>
                        <div className='lightCard-title'>{t('profile.address.headline')}</div>
                        <Row>
                            <Col xs={{span: 12, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}}>
                                <div className='lightCard-inputLabel' htmlFor='country'>{t('profile.address.country')}</div>
                                <div className='lightCard-inputLabel' htmlFor='user_address'>{t('profile.address.street')}</div>
                                <div className='lightCard-inputLabel' htmlFor='user_zipcode'>{t('profile.address.zipCode')}</div>
                                <div className='lightCard-inputLabel' htmlFor='user_city'>{t('profile.address.city')}</div>
                                <div className='lightCard-inputLabel' htmlFor='state'>{t('profile.address.state')}</div>
                            </Col>
                            <Col xs={{span: 12, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}}>
                                <Select className='lightCard-inputTextBox' id='country'
                                        defaultValue={data?.user_country?.id || countries[0].id}
                                        onChange={(e) => changeValue(e, 'user_country_id')}>
                                    {countries?.map(rec => <option key={rec.id}>{rec.name}</option>)}
                                </Select>
                                <Input className='lightCard-inputTextBox' id='user_address' defaultValue={data?.user_address}
                                       onChange={(e) => changeValue(e.target.value, 'user_address')}/>
                                <Input className='lightCard-inputTextBox' id='user_zipcode' defaultValue={data?.user_zipcode}
                                       onChange={(e) => changeValue(e.target.value, 'user_zipcode')}/>
                                <Input className='lightCard-inputTextBox' id='user_city' defaultValue={data?.user_city}
                                       onChange={(e) => changeValue(e.target.value, 'user_city')}/>
                                <Input className='lightCard-inputTextBox' id='state' defaultValue={data?.user_state}
                                       onChange={(e) => changeValue(e.target.value, 'user_state')}/>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={{span: 24, order: 5}} sm={{span: 24, order: 5}} md={{span: 12, order: 5}} lg={{span: 12, order: 5}} xl={{span: 8, order: 5}}>
                    <div className='lightCard editProfileLightCard'>
                        <div className='lightCard-title'>{t('profile.recommender.headline')}</div>
                        <Row>
                            <Col xs={{span: 12, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}}>
                                <div className='lightCard-inputLabel' htmlFor='country'>{t('profile.recommender.name')}</div>
                                <div className='lightCard-inputLabel' htmlFor='user_address'>{t('profile.recommender.id')}</div>
                                <div className='lightCard-inputLabel' htmlFor='user_zipcode'>{t('profile.recommender.phone')}</div>
                                <div className='lightCard-inputLabel' htmlFor='user_city'>{t('profile.recommender.email')}</div>
                            </Col>
                            <Col xs={{span: 12, order: 1}} md={{span: 12, order: 1}} lg={{span: 12, order: 1}}>
                                <div className='lightCard-inputLabel' title={data?.sponsor?.user_name || 'No sponsor'}>{data?.sponsor?.user_name || 'No sponsor'}</div>
                                <div className='lightCard-inputLabel' title={data?.sponsor?.id || 'No sponsor'}>{data?.sponsor?.id || 'No sponsor'}</div>
                                <div className='lightCard-inputLabel' title={data?.sponsor?.user_phone || 'No sponsor'}>{data?.sponsor?.user_phone || 'No sponsor'}</div>
                                <div className='lightCard-inputLabel' title={data?.sponsor?.user_email || 'No sponsor'}>{data?.sponsor?.user_email || 'No sponsor'}</div>
                            </Col>
                        </Row>
                        <div className='shareWithUplineDiv'>
                            <Checkbox defaultChecked={data?.share_data}  className='shareWithUplineCheckbox'
                                      onChange={(e) => changeValue(e.target.checked, 'share_data')}>
                            </Checkbox>
                            <div className='lightCard-inputLabel'>{t('profile.recommender.share')}</div>
                        </div>
                    </div>
                </Col>
                <Col xs={{span: 24, order: 6}} sm={{span: 24, order: 6}} md={{span: 12, order: 6}} lg={{span: 12, order: 6}} xl={{span: 8, order: 6}}>
                    <div className='lightCard passwordCard editProfileLightCard'>
                        <div className='lightCard-title'>{t('profile.password.headline')}</div>
                        <ChangePassword/>
                    </div>
                </Col>
            </Row>
            <div className='darkCard saveSettingsCard margin-bot18'>
                <button onClick={e => {
                    e.preventDefault()
                    const validation = validateFormData(updateData);
                    if (!validation.success) {
                        for (const e of validation.missing)
                            $('#' + e).css('border', '2px solid red');
                        Swal.fire({icon: 'error', title: 'Error', text: 'Missing required field', confirmButtonColor: '#FFA500'})
                    } else
                        return updatePro()
                }}>{t('profile.save')}</button>
            </div>
        </div>
    )
}

export default EditProfile
