import {gql} from '@apollo/client'

export const GetTeamInfo = gql`
query($salespackage_ids: [String]!){
    me{
        totalPartner : downline_numb,
        activeTotalPartnerPro: downline_numb(
                is_subscription_active:true,
                salespackage_ids : $salespackage_ids
                ),
        downline{
           id, 
           user_firstname, 
           user_lastname, 
           share_data,
           mlm_level, 
           user_email, 
           user_phone,
           created_at, 
           direct_partner: downline_numb(lvl:1),
           indirect_partner: downline_numb(lvl:2)
           is_subscription_active 
           currentSalesPackage{
            name
           }
        }
    }
}
`
