import {gql} from '@apollo/client'

export const LogOut = gql`
    query {
        logout {
            result
            error
        }
    }
`

export const GetHeaderInfo = gql`
query($salespackage_ids: [String]!){
  me {
    currentSalesPackage {
        name
    }
    user_firstname
    user_lastname
    activeDirectPartnerPro: downline_numb(
      is_subscription_active:true,
         lvl:1,
         salespackage_ids : $salespackage_ids
    )
    activeIndirectPartnerPro: downline_numb(
      is_subscription_active:true,
         lvl:2,
         salespackage_ids : $salespackage_ids
    )
  }
}`
