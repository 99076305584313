import React, {useState} from 'react'
import {useApolloClient} from "@apollo/client";

import '../authStyles.scss'
import {forgotPassword} from '../authQueries'
import {throwInternError} from "../../../utils/errorHandler";
import {showSuccessfulMessage, showErrorMessage} from "../../../utils/messageHandler";
import {isValidMail, trimInput} from "../../../utils/validations";
import {useTranslation} from "react-i18next";
import {
    validation,
    ValidationInputField
} from "../../fields/validations";
const _ = require('lodash');

const ForgotPassword = () => {
    const {t} = useTranslation();
    const client = useApolloClient();
    const [inputValues, setInputValue] = useState({
        email: {value: '', valid: false, messages: [], callbackVariable: 'email', validations: [isValidMail]}
    });

    const requestPasswordReset = async () => {
        if(!inputValues.email.valid){
            validation(inputValues.email, {callback: changeValue});
        }

        try {
            const {data} = await client.query({
                query: forgotPassword,
                variables: {email: inputValues.email.value},
                fetchPolicy: "no-cache"
            });
            data?.requestResetPassword?.result === true ?
                showSuccessfulMessage(t('forgot_password_verification_mail_sent')) :
                showErrorMessage(data?.requestResetPassword?.error);
        } catch (e) {
            throwInternError(e);
        }
    }

    const changeValue = (val, id, valid, messages) => {
        let newInputValue = _.clone(inputValues);
        newInputValue[id].value = trimInput(val);

        valid !== null && valid !== undefined && (newInputValue[id].valid = valid);
        messages !== null && messages !== undefined && (newInputValue[id].messages = messages);

        setInputValue(newInputValue);
    }

    return (
        <div>
            <div className='register-header-label'>Forgot password</div>
            <ValidationInputField callback={changeValue} styles='register-field' inputValue={inputValues?.email}
                                  placeholder='E-Mail*' id='forgot-password-email'
            />
            <button
                className={'register-button'}
                onClick={async () => {
                    await requestPasswordReset()
                }}
                type='submit'>{t('forgot_password_submit_button')}
            </button>
        </div>
    );
}

export default ForgotPassword;
