import React from "react";
import {Route, Redirect} from "react-router-dom";
import PropTypes from "prop-types";

const GuestRoute = ({children, ...rest}) => {
    return (
        <Route
            {...rest}
            render={({location}) =>
                localStorage.getItem("login") === null ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location},
                        }}
                    />
                )
            }
        />
    );
};

GuestRoute.propTypes = {
    children: PropTypes.node.isRequired,
};
export default GuestRoute;
