import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import UserRouter from './userRouter'
import GuestRouter from './guestRouter'

const MainRouter = () => {

    return (
        <Router basename={'/backoffice'}>
            <Switch>
                <Route path='/auth' component={GuestRouter}/>
                <Route path='/ref' component={GuestRouter}/>
                <Route path='/' component={UserRouter}/>
            </Switch>
        </Router>
    )
}

export default MainRouter
