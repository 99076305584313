import React, {useState} from 'react'
import './style.scss'
import '../../nsw-frontend-library/styles/global.scss'
import '../../nsw-frontend-library/components/cards/cardStyles.scss'
import {Col, Row, Select} from 'antd'
import {STRIPE_PUBLIC_KEY, SUCCESS_PAYMENT_REDIRECT_URL, FAILURE_PAYMENT_REDIRECT_URL} from '../../utilities/constants'
import {loadStripe} from '@stripe/stripe-js';
import {useQuery, useMutation} from "@apollo/client";
import * as queries from "./queries";
import * as defaultQueries from '../../nsw-frontend-library/queries/queries'
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import {FaCheckCircle} from "react-icons/fa";
import {BsFillXCircleFill} from "react-icons/bs"
import {IconContext} from "react-icons";
import {GUTTER_SPACE} from '../../utilities/constants'
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";

const Shop = () => {
    const {t} = useTranslation();
    const {data: current, loading: currentLoading} = useQuery(defaultQueries.GetCurrentSalesPackage())
    const {data: pm, loading: pmLoading} = useQuery(queries.getPaymentMethods())

    const [product, setProduct] = useState({
        subscriptionpaymentmethod_id: '',
        success_url: SUCCESS_PAYMENT_REDIRECT_URL,
        failure_url: FAILURE_PAYMENT_REDIRECT_URL
    });

    const changeProduct = (val, id) => {
        setProduct({
            ...product,
            [id]: val
        })
    }

    const [buyProduct] = useMutation(queries.createPayment, {
        onCompleted: async d => {
            console.log(d);
            if (d?.createPayment) {
                const {redirect, is_stripe} = d.createPayment;
                if (is_stripe) {
                    const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
                    await stripe.redirectToCheckout({
                        sessionId: redirect
                    });
                } else
                    window.location.href = redirect;
            } else {
                Swal.fire({icon: 'error', title: 'Error', text: d.error, confirmButtonColor: '#FFA500'})
            }
        },

        onError: e => {
            console.log(e);
            Swal.fire({icon: 'error', title: 'Error', text: e.toString(), confirmButtonColor: '#FFA500'})
        }
    })

    if (currentLoading || pmLoading) return <Loading/>

    const currentSalesPackage = current?.me?.currentSalesPackage?.name;
    const paymentMethods = pm?.subscriptionPaymentMethods?.map(sp => ({
        is_auto_renew: sp?.subscriptionPackage?.is_auto_renew,
        id: sp.id,
        price: sp?.subscriptionPackage?.price?.toFixed(2),
        text: sp?.paymentMethod?.name?.replace(/^./, sp?.paymentMethod?.name[0].toUpperCase()) + ': ' + (sp?.subscriptionPackage?.is_auto_renew ? t('shop.purchase.select.type.monthly') : t('shop.purchase.select.type.single', {months: sp?.subscriptionPackage?.num_months}))
    }));

    const checkedCircle = () => {
        return <IconContext.Provider
            value={{color: 'green', size: '1.45em'}}>
            <FaCheckCircle/>
        </IconContext.Provider>
    }

    const halfCheckedCircle = () => {
        return <IconContext.Provider
            value={{color: 'red', size: '1.45em'}}>
            <FaCheckCircle/>
        </IconContext.Provider>
    }

    const uncheckedCircle = () => {
        return <IconContext.Provider
            value={{color: 'red', size: '1.45em'}}>
            <BsFillXCircleFill/>
        </IconContext.Provider>
    }

    const listItems = [
        {name: t('shop.benefits.package.home'), isBasicAvailable: false, isProfessionalAvailable: true},
        {name: t('shop.benefits.package.dashboard'), isBasicAvailable: true, isProfessionalAvailable: true},
        {name: t('shop.benefits.package.profile'), isBasicAvailable: true, isProfessionalAvailable: true},
        {name: t('shop.benefits.package.team'), isBasicAvailable: true, isProfessionalAvailable: true},
        {name: t('shop.benefits.package.teamTree'), isBasicAvailable: false, isProfessionalAvailable: true},
        {name: t('shop.benefits.package.commissions'), isBasicAvailable: true, isProfessionalAvailable: true, limitedAvailableInBasic: true},
        {name: t('shop.benefits.package.share'), isBasicAvailable: true, isProfessionalAvailable: true},
        {
            name: t('shop.benefits.package.mediaCenterLimited'),
            isBasicAvailable: true,
            isProfessionalAvailable: true,
            proName: t('shop.benefits.package.mediaCenter'),
            limitedAvailableInBasic: true
        },
        {name: t('shop.benefits.appFeatures.headline'), isHeader: true},
        {name: t('shop.benefits.appFeatures.sessions.create'), isBasicAvailable: false, isProfessionalAvailable: true},
        {name: t('shop.benefits.appFeatures.sessions.upcoming'), isBasicAvailable: false, isProfessionalAvailable: true},
        {name: t('shop.benefits.appFeatures.sessions.completed'), isBasicAvailable: false, isProfessionalAvailable: true},
        {name: t('shop.benefits.appFeatures.growthCalculator'), isBasicAvailable: false, isProfessionalAvailable: true},
        {name: t('shop.benefits.incentive.headline'), isHeader: true},
        {name: t('shop.benefits.incentive.entitlement'), isBasicAvailable: false, isProfessionalAvailable: true},
        {
            name: t('shop.benefits.incentive.numberSessions'),
            isBasicAvailable: false,
            isProfessionalAvailable: true
        },
    ]

    const SubscriptionFeatureList = (isProfessionalList) => {
        return <div>
            {listItems.map(a => {
                if (a.isHeader) {
                    return <div className='featureListHeader'>{a.name}</div>
                }
                return <div className='lightCard-inputLabel featureItemDiv'>
                    {(isProfessionalList ? a.isProfessionalAvailable : a.isBasicAvailable) ? (a.limitedAvailableInBasic && !isProfessionalList ? halfCheckedCircle() : checkedCircle()) : uncheckedCircle()}
                    <div className='featureItemName'>{isProfessionalList && a.proName ? a.proName : a.name}</div>
                </div>
            })}
        </div>
    }

    return (
        <div className='shop'>
            <Row gutter={[GUTTER_SPACE, GUTTER_SPACE]}>
                <Col xs={{span: 24, order: 2}} sm={{span: 24, order: 2}} md={{span: 24, order: 2}}
                     lg={{span: 24, order: 2}} xl={{span: 24, order: 2}} xxl={{span: 8, order: 1}}>
                    <div className='lightCard productCard'>
                        <div className='lightCard-title shopBasicTitle'>{t('shop.benefits.package.headline.basic')}</div>
                        {SubscriptionFeatureList(false)}
                    </div>
                </Col>
                <Col xs={{span: 24, order: 1}} sm={{span: 24, order: 1}} md={{span: 24, order: 1}}
                     lg={{span: 24, order: 1}} xl={{span: 24, order: 1}} xxl={{span: 16, order: 2}}>
                    <div className='lightCard productCard'>
                        <Row>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                                 lg={{span: 12}} xl={{span: 10}} xxl={{span: 10}}>
                                <div className='lightCard-title'>{t('shop.benefits.package.headline.pro')}</div>
                                {SubscriptionFeatureList(true)}
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                                 lg={{span: 12}} xl={{span: 14}} xxl={{span: 14}}>
                                <div className='lightCard-title shopPriceTitle'>
                                    <span>{t('shop.purchase.price', {price: product.subscriptionpaymentmethod_id && product.subscriptionpaymentmethod_id !== '0' ?
                                            paymentMethods?.find(x => x.id === product.subscriptionpaymentmethod_id)?.price :
                                            '29,90'})}</span>
                                    <span style={{fontSize: '16px', fontWeight: 'normal', marginLeft: '2%'}}>{paymentMethods?.find(x => x.id === product.subscriptionpaymentmethod_id)?.is_auto_renew === false ? t('shop.purchase.priceSpecial', {price: '358.80'}) : ''}</span>
                                    <span className="shopPriceVatReferenceText">{t('shop.purchase.beforeTax')}</span>
                                </div>
                                <div>
                                    <Select className='inputStyle shopPaymentMethodSelection' id='paymentType'
                                            defaultValue='0'
                                            onChange={(e) => changeProduct(e, 'subscriptionpaymentmethod_id')}>
                                        <option key='0'>{t('shop.purchase.select.default')}</option>
                                        {paymentMethods?.map(rec => <option key={rec.id}>{rec.text}</option>)}
                                    </Select>
                                </div>
                                <div>
                                    <button className='lightCard-button shopBuyButton'
                                            disabled={currentSalesPackage === 'Professional'}
                                            onClick={() => buyProduct({variables: product})}>{currentSalesPackage !== 'Professional' ? t('shop.purchase.buy') : t('shop.purchase.alreadyActive')}</button>
                                </div>
                                <img id='shopSession' className='shopSessionImage' src='/backend/file/91c6598c7f47'
                                     alt=""/>
                                <div className='shopProductSpecialOffer'>{t('shop.purchase.specialOffer')}</div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Shop
