import {gql} from '@apollo/client'

export const getSalesDealsQuery = gql`
query{
  me {
      deposits{
        paymentMethod{
          name
        }
        sale{
          id
          order_number
          invoice_number
          amount
          fullpaid_at
          subscriptionPackage{
            num_months
          }
          salesPackage{
            name
          }
        }
      }
  }
}
`

export const checkNecessaryInvoiceData = gql`
query{
  me{
  user_city
  user_address
  user_zipcode
  user_firstname
  user_lastname
}
}
`


