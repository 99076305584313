import React, { useState } from 'react'
import MainRouter from '../routers/mainRouter'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

function Client() {
    const [error, setError] = useState(null)
    const link = createHttpLink({
        uri: '/backend/api',
        credentials: 'include',
    });
    const LogoutLink = onError(({networkError, graphQLErrors}) => {
        if(networkError){
            console.log(networkError);
            if (networkError.statusCode === 401) {
                localStorage.removeItem('login')
                window.location.href = '/auth/login'
            } else {
                setError(networkError.message)
            }
        }
        if(graphQLErrors){
            console.log(graphQLErrors);
            for (const graphQlError of graphQLErrors){
                setError(graphQlError.message);
            }
        }
    })
    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: LogoutLink.concat(link)
    });

    if (error) return <div>{error}</div>
    return (
        <ApolloProvider client={client}>
            <MainRouter />
        </ApolloProvider>
    );
}

export default Client;
