import './style.scss'
import './../../style.scss'
import '../../nsw-frontend-library/components/cards/cardStyles.scss'
import {Row, Col} from 'antd'
import ReactPlayer from 'react-player'
import {useLazyQuery} from '@apollo/client'
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import {VscFilePdf} from "react-icons/all";
import React, {useState} from "react";
import {useEffect} from "react";
import * as defaultQueries from "../../nsw-frontend-library/queries/queries";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {GUTTER_SPACE} from '../../utilities/constants'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';



const responsiveCarousel = {
    desktop: {
        breakpoint: { max: 3000, min: 0 },
        items: 1,
        slidesToSlide: 1.
    },
    tablet: {
        breakpoint: { max: 3000, min: 0 },
        items: 1,
        slidesToSlide: 1.
    },
    mobile: {
        breakpoint: { max: 3000, min: 0 },
        items: 1,
        slidesToSlide: 1.
    }
};

const MediaCenter = () => {
    let [language, setLanguage] = useState('');
    const { t } = useTranslation();
    let [getVideos, {data: videos, loading: videosReqLoading}] = useLazyQuery(defaultQueries.GetDownloadFilesOfType, {fetchPolicy: 'no-cache'});
    let [getDocuments, {
        data: documents,
        loading: documentsReqLoading
    }] = useLazyQuery(defaultQueries.GetDownloadFilesOfType, {fetchPolicy: 'no-cache'});
    let [getTerms, {
        data: terms,
        loading: termsReqLoading
    }] = useLazyQuery(defaultQueries.GetDownloadFilesOfType);
    let [getRecommenderAgreement, {
        data: recommenderAgreement,
        loading: recommenderAgreementReqLoading
    }] = useLazyQuery(defaultQueries.GetDownloadFilesOfType);

    if (!videos && !videosReqLoading) {
        getVideos({variables: {file_type: 'mediacenter-video'}});
    }

    if (!documents && !documentsReqLoading) {
        getDocuments({variables: {file_type: 'mediacenter-document'}});
    }

    if (!terms && !termsReqLoading) {
        getTerms({variables: {file_type: 'terms-conditions'}});
    }

    if (!recommenderAgreement && !recommenderAgreementReqLoading) {
        getRecommenderAgreement({variables: {file_type: 'recommender-agreement'}});
    }


    i18n
        .on('languageChanged', (lng) => {
            if(lng !== language)
                setLanguage(lng);
        })

    useEffect(() => {
        getDocuments({variables: {file_type: 'mediacenter-document'}});
        getVideos({variables: {file_type: 'mediacenter-video'}});
    }, [language, getDocuments, getVideos])


    if (videosReqLoading || documentsReqLoading || termsReqLoading || recommenderAgreementReqLoading) return <Loading/>

    const mainVideo = videos?.files ? JSON.parse(JSON.stringify(videos?.files)).sort((a,b) => a.sort_number - b.sort_number)[0] : [];
    let slideVideos = videos?.files ? JSON.parse(JSON.stringify(videos?.files)).sort((a,b) => a.sort_number - b.sort_number) : [];
    slideVideos.shift();
    debugger;
    const documentDataOld = documents?.files ? JSON.parse(JSON.stringify(documents?.files)).sort(x => x.sort_number) : [];
    const termsAndConditions = terms?.files ? JSON.parse(JSON.stringify(terms?.files)) : [];
    const recommender = recommenderAgreement?.files ? JSON.parse(JSON.stringify(recommenderAgreement?.files)) : [];
    const documentData = [];
    documentData.push(...documentDataOld, ...termsAndConditions, ...recommender);
    console.log(slideVideos);
    console.log(documentData);


    return (
        <div className='mediaCenter'>
            <Row gutter={[GUTTER_SPACE, GUTTER_SPACE]}>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}>
                    <div className='lightCard'>
                        <Row>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xxl={{span: 12}}>
                                <div className='padding-10'>
                                    <ReactPlayer width='100%' height='100%' controls={true} url={mainVideo?.url}  />
                                </div>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xxl={{span: 12}}>
                                <div className='mainContent display-linebreak'>{mainVideo?.content}</div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 12}} xxl={{span: 12}}>
                    <div className='darkCard mediaCenterCard'>
                        {!slideVideos ? <br/> : <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={true}
                            responsive={responsiveCarousel}
                            infinite={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                        >
                            {slideVideos?.map((val) => (
                                <div className='lightCard mainVideoCard'>
                                    <div className='wrapperPlayer' >
                                        <ReactPlayer width='100%' height='100%' controls={true} url={val.url}  />
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                        }
                    </div>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 12}} xxl={{span: 12}}>
                    <div className='lightCard mediaCenterCard'>
                        <h className='lightCard-title'>{t('mediacenter.downloadFiles.title')}</h>
                        <br/>
                        {documentData?.map(d => {
                            return <div className='mediacenter-pdf-item'>
                                <VscFilePdf className='pdfIcon'/>
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a target='_blank' href={d.url} style={{fontSize: '14px'}}>
                                    {d.file_name}
                                </a>
                            </div>
                        })}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default MediaCenter
