import {gql} from '@apollo/client'

export const GetCardsData = gql`
query($salespackage_ids: [String]!){
me {
    is_subscription_active
    directPartner: downline_numb(lvl:1)
    indirectPartner: downline_numb(lvl:2)
    totalPartner : downline_numb
    activeDirectPartnerPro: downline_numb(
        is_subscription_active:true,
        lvl:1,
        salespackage_ids : $salespackage_ids
        )
    activeIndirectPartnerPro: downline_numb(
        is_subscription_active:true,
        lvl:2,
        salespackage_ids : $salespackage_ids
        )
    activeTotalPartnerPro: downline_numb(
        is_subscription_active:true,
        salespackage_ids : $salespackage_ids
        )
    }         
}`

