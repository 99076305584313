import React, {useEffect} from 'react'
import {validateToken} from '../authQueries'
import {useLazyQuery} from "@apollo/client";
import {Loading} from "../../utils/loadingPageScreen";
import {showSuccessfulMessage, showErrorMessage} from "../../../utils/messageHandler";
import {throwInternError} from '../../../utils/errorHandler';
import {useTranslation} from "react-i18next";

const RegisterEmailConfirmation = () => {
    const {t} = useTranslation();

    let [checkToken, {loading}] = useLazyQuery(validateToken, {
        onCompleted: d => {
            d?.completeRegistration?.result === true ?
                showSuccessfulMessage(t('register_email_confirmation_success')) :
                showErrorMessage(d?.completeRegistration?.error);

            setTimeout(() => window.location.href = '/backoffice/auth/login', 5000);
        },
        onError: e => {
            throwInternError(e);
        }
    });

    useEffect(() => {
        const t = (new URLSearchParams(window.location.search))?.get('token');
        if (!t) {
            showErrorMessage('invalid token');
            return;
        }
        checkToken({variables: {emailToken: t}});
    }, [checkToken])

    if (loading) return <Loading/>

    return (
        <div>
        </div>
    )
}

export default RegisterEmailConfirmation