import {gql} from '@apollo/client'

export const sendData = data => {
    const query = `
    query {
        login(
            ${Object.keys(data).map(val => `${val} : "${data[val]}"`)}
        ){
            result
            error
            bytePoetCookie
        }
    }
    `
    return gql`${query}`;
}
