import React from 'react'
import './loadingPageScreen.scss'
import {LoadingOutlined} from '@ant-design/icons'

export const Loading = () => {
    return (
        <div className='loading'>
            <LoadingOutlined className='loading-icon' type="loading"/>
        </div>
    )
}
