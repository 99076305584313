import React from 'react'
import './style.scss'
import '../register/style.scss'
import '../../nsw-frontend-library/styles/global.scss'
import '../../nsw-frontend-library/styles/fields.scss'
import '../../nsw-frontend-library/components/cards/cardStyles.scss'
import {Button, message} from 'antd'
import {ShareAltOutlined} from '@ant-design/icons'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useQuery} from '@apollo/client'
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import * as queries from './queries'
import {useTranslation} from "react-i18next";
import Register from '../../nsw-frontend-library/components/auth/register'

const Share = () => {
    const {t} = useTranslation();
    const {data: userRefCode, loading: userRefCodeReqLoading} = useQuery(queries.GetUserRefCode);

    if (userRefCodeReqLoading) return <Loading/>
    const data = userRefCode?.me

    const hostnameWithoutBackoffice = 'https://' + window.location.hostname + '/ref/' + data?.referrer_code;

    return (
        <div className="share">
            <div className='lightCard share-link'>
                <div>
                    <a className="overflowHidden" href={hostnameWithoutBackoffice} style={{display: 'inline-block', width: '100%'}} title={hostnameWithoutBackoffice}
                       onClick={e => e.preventDefault()}>{hostnameWithoutBackoffice}</a>
                </div>
                <div style={{width: '100%'}} className="overflowHidden">
                    <CopyToClipboard text={hostnameWithoutBackoffice}
                                     onCopy={() => message.success('copied link to clipboard!')}>
                        <Button shape='round' type="primary" style={{fontWeight: 'bold', maxWidth: '100%'}}
                                icon={<ShareAltOutlined/>}>{t('share.copyToClipboard')}</Button>
                    </CopyToClipboard>
                </div>
            </div>
            <div className='share-register'>
                <div className='share-register-header'>Register</div>
                <Register referrer={data?.referrer_code}/>
            </div>
        </div>
    )
}
export default Share
