import {gql} from "@apollo/client";

export const resetPassword = data => {
    const query = `
    query {
        resetPassword(
            ${Object.keys(data)?.map(val => `${val} : "${data[val]?.value}"`)}
        ){
            result
            error
        }
    }
    `
    return gql`${query}`
}
