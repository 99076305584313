import {gql} from "@apollo/client";

export const checkProStatus = gql`
query {
  me {
  id
  user_name
  user_firstname
  user_lastname
  user_type
  profile_picture_url
    currentSalesPackage {
        name
    }
  }
}`

