import React from 'react'
import './style.scss'

const GlobalUserLayout = ({children}) => {
    return (
        <div className='globalUserLayout'>
            {children}
        </div>
    )
}

export default GlobalUserLayout
