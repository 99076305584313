import React, {useState} from 'react'
import './style.scss'
import '../../nsw-frontend-library/styles/global.scss'
import '../../nsw-frontend-library/components/cards/cardStyles.scss'
import {Input, Button, Row, Col} from 'antd'
import {useMutation} from "@apollo/client";
import * as queries from "../feedback/queries";
import {search} from "ss-search";
import Highlighter from 'react-highlight-words'
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import {showSuccessfulMessage} from "../../nsw-frontend-library/utils/messageHandler";

const {TextArea} = Input;

function getFaqQuestions(t){
    return [
        {
            id: 1,
            question: t('feedback.howTo.session.start.question'),
            answer: t('feedback.howTo.session.start.answer')
        },
        {
            id: 3,
            question: t('feedback.howTo.registerPartner.question'),
            answer: t('feedback.howTo.registerPartner.answer')
        },
        {
            id: 4,
            question: t('feedback.howTo.noActivationMail.question'),
            answer: t('feedback.howTo.noActivationMail.answer')
        },
        {
            id: 5,
            question: t('feedback.howTo.growthCalculator.question'),
            answer: t('feedback.howTo.growthCalculator.answer')
        },
        {
            id: 6,
            question: t('feedback.howTo.appNotInGooglePlayStore.question'),
            answer: t('feedback.howTo.appNotInGooglePlayStore.answer')
        },
        {
            id: 7,
            question: t('feedback.howTo.costOfUsage.question'),
            answer: t('feedback.howTo.costOfUsage.answer')
        },
        {
            id: 8,
            question: t('feedback.howTo.session.limit.question'),
            answer: t('feedback.howTo.session.limit.answer')
        },
        {
            id: 9,
            question: t('feedback.howTo.howMuchMoneyCanIEarn.question'),
            answer: t('feedback.howTo.howMuchMoneyCanIEarn.answer')
        },
        {
            id: 10,
            question: t('feedback.howTo.notAllowedToDownloadApp.question'),
            answer: t('feedback.howTo.notAllowedToDownloadApp.answer')
        },
        {
            id: 11,
            question: t('feedback.howTo.whyEnterBankDetails.question'),
            answer: t('feedback.howTo.whyEnterBankDetails.answer')
        },
        {
            id: 12,
            question: t('feedback.howTo.changeRecommender.question'),
            answer: t('feedback.howTo.changeRecommender.answer')
        },
        {
            id: 13,
            question: t('feedback.howTo.limitPartners.question'),
            answer: t('feedback.howTo.limitPartners.answer')
        }
    ];
}


const Feedback = () => {
    const {t} = useTranslation();
    const [language, setLanguage] = useState();
    const [faqList, setFaqList] = useState();
    const [amountOfQuestions, setAmountOfQuestions] = useState(5);
    const [filterText, setFilterText] = useState();
    let faqListValue = getFaqQuestions(t);

    i18n
        .on('languageChanged', (lng) => {
            if(lng !== language){
                setLanguage(lng);
                faqListValue = getFaqQuestions(t);
                let modifiedList = faqListValue ? JSON.parse(JSON.stringify(faqListValue)) : [];
                modifiedList.forEach(x => x.collapsed = true);
                setFaqList(modifiedList)
            }
        })





    const [sendFeedback] = useMutation(queries.sendFeedback, {
        onCompleted: d => {
            if (d?.sendSupportTicketViaMail === true) {
                document.getElementById('subject').value = "";
                document.getElementById('message').value = "";
                showSuccessfulMessage('Feedback has been sent');
            }
        }
    });

    if(!faqList){
        let modifiedList = faqListValue ? JSON.parse(JSON.stringify(faqListValue)) : [];
        modifiedList.forEach(x => x.collapsed = true);
        setFaqList(modifiedList)
    }

    const searchKeys = ["question", "answer"];
    let filteredList = faqList ? JSON.parse(JSON.stringify(faqList)) : [];
    filteredList = search(filteredList, searchKeys, filterText);
    const isMaxFaqLength = filteredList?.length <= amountOfQuestions;

    if(filteredList){
        filteredList =  isMaxFaqLength ? filteredList : filteredList.splice(0, amountOfQuestions);
    }

    function collapseQuestion(id, isCollapsed){
        let updatedFaqList = JSON.parse(JSON.stringify(faqList));
        updatedFaqList.find(x => x.id === id).collapsed = !isCollapsed;
        setFaqList(updatedFaqList);
    }

    const FaqQuestion = (data, onCollapse) => {
        const searchWords =  filterText ? filterText.split(' ') : []
        return (
            <div className='questionCard'>
                <div className='faqCard'>
                    <div className='faqButtonCard'>
                        <Button shape="round" className='faqButton'
                                onClick={() => onCollapse(data.id, data.collapsed)}>{data.collapsed ? '▼' : '▲'}</Button>
                    </div>
                    <div>
                        <div className='faqQuestion'>
                            <Highlighter
                                highlightClassName="searchHighlight"
                                searchWords={searchWords}
                                textToHighlight={data.question}
                            />
                        </div>
                        <div className='faqAnswer' style={{display: data.collapsed ? 'none' : 'block'}}>
                            <Highlighter
                                highlightClassName="searchHighlight"
                                searchWords={searchWords}
                                textToHighlight={data.answer}
                            />
                        </div>
                    </div>
                </div>
            </div>)
    }

    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className='lightCard faqSearch'>
                    <Input id='teamFilter' placeholder='search...'
                           onChange={e => {
                               setFilterText(e.target.value);
                           }}
                    />
                </div>
            </div>
            <Row gutter={40}>
                <Col lg={{span: 14, offset: 0, order: 1}}
                     md={{span: 24, offset: 0, order: 2}}
                     sm={{span: 24, offset: 0, order: 2}}
                     xs={{span: 24, offset: 0, order: 2}}>
                    <div>
                        <div className='page'>
                            {filteredList?.map(val => {
                                return FaqQuestion(val, collapseQuestion);
                            })}
                        </div>
                        <div className='faqLoadMoreButtonCard'>
                            <Button shape="round" className='faqLoadMoreButton'
                                    style={{display: isMaxFaqLength ? 'none' : 'block'}}
                                    onClick={() => setAmountOfQuestions(amountOfQuestions + 5)}>
                                {t('feedback.showMore')}
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 10, offset: 0, order: 2}}
                     md={{span: 24, offset: 0, order: 1}}
                     sm={{span: 24, offset: 0, order: 1}}
                     xs={{span: 24, offset: 0, order: 1}}>
                    <div className='feedback page margin-bot10 lightCard'>
                        <div>
                            <label htmlFor='subject'>{t('feedback.subject')}</label>
                            <Input className='inputStyle' id='subject'/>
                        </div>
                        <div className='sec2'>
                            <label htmlFor="message">{t('feedback.message')}</label>
                            <TextArea className='inputStyle' id='message' rows={4}/>
                        </div>
                        <Button shape="round" type="primary" onClick={e => {
                            e.preventDefault()
                            const subject = document.getElementById('subject').value
                            const message = document.getElementById('message').value
                            return sendFeedback({variables: {subject, message}})
                        }}>{t('feedback.send')}</Button>
                    </div>
                </Col>
            </Row>
        </div>)
}
export default Feedback