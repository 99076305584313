import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import './i18n';
import * as $ from 'jquery';

$(document)
    .on('mouseover', '[title]', function () {
        const container = this;  // or `this.parentNode`
        const title = this.title || this.getAttribute('title') || this.textContent || this.value;
        const overflowed = container.scrollWidth > container.clientWidth;
        this.title = overflowed ? title : '';
    })
    .on('mouseout', '[title]', function() {
        this.title = '';
    });

Sentry.init({
    dsn: 'https://26ab692d12a6485f81ea6fe16225e47d@s.vougee.gmbh/7'
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
