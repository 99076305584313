import {gql} from '@apollo/client'

export const getPaymentMethods = () => {
    const query = `
query {
  subscriptionPaymentMethods{
    id
    paymentMethod{
      name
    }
    subscriptionPackage{
      is_auto_renew
      num_months
      price
      salespackage{
        name
      }
    }
  }
}
        `
    return gql`${query}`;
}

export const createPayment = gql`
mutation($subscriptionpaymentmethod_id: String!, $success_url: String!, $failure_url: String!){
  createPayment(subscriptionpaymentmethod_id: $subscriptionpaymentmethod_id, success_url: $success_url, failure_url: $failure_url)
}
`
