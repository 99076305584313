import {gql} from '@apollo/client'

const userData = `id, 
   user_firstname, 
   user_lastname,
   user_referrer_id,
   share_data,
   mlm_level, 
   user_email, 
   user_phone,
   created_at, 
   profile_picture_url,
   is_subscription_active 
   currentSalesPackage{
    name
   }
   directPartner: downline_numb(lvl:1)
   indirectPartner: downline_numb(lvl:2)
   totalPartner : downline_numb
   activeDirectPartnerPro: downline_numb(
       is_subscription_active:true,
       lvl:1,
       salespackage_ids : $salespackage_ids
   )
   activeIndirectPartnerPro: downline_numb(
       is_subscription_active:true,
       lvl:2,
       salespackage_ids : $salespackage_ids
   )
   activeTotalPartnerPro: downline_numb(
       is_subscription_active:true,
       salespackage_ids : $salespackage_ids
   )`;

export const GetDirectPartners = gql`
query($user_id: String!, $salespackage_ids: [String]!){
  direct_partners(user_id: $user_id){
    ${userData}
  }
}
`

export const GetDownline = gql`
    query($salespackage_ids: [String]!, $toLvl: Int!){
        me{
            ${userData}
            downline(toLvl: $toLvl){
               ${userData}
            }
        }
    }
`
