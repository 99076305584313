import React, {useEffect, useState} from 'react'
import './style.scss'
import '../../nsw-frontend-library/styles/global.scss'
import '../../nsw-frontend-library/components/cards/cardStyles.scss'
import {Tooltip} from 'antd'
import Tree from "react-d3-tree"
import {useLazyQuery, useApolloClient} from "@apollo/client";
import * as queries from "./queries";
import * as defaultQueries from '../../nsw-frontend-library/queries/queries'
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import {ImArrowDown, ImArrowUp} from "react-icons/all";
import PropTypes from "prop-types"
import "./style.scss";
import {useTranslation} from "react-i18next";
import "./style.scss"
import photo_placeholder from '../../nsw-frontend-library/assets/photo_placeholder.jpg';
import * as Bowser from "bowser";

const TeamTree = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const {t} = useTranslation();
    const client = useApolloClient();
    // eslint-disable-next-line
    const [treeX, setTreeX] = useState(256)
    const [downlineData, setDownlineData] = useState();

    const [getSalesPackages, {
        data: salesPackageIds,
        loading: salesPackageIdsReqLoading
    }] = useLazyQuery(defaultQueries.GetSalesPackageIDs)
    const [getDownline, {
        data: downlineQueryData,
        loading: downlineDataReqLoading
    }] = useLazyQuery(queries.GetDownline, {
        skip: salesPackageIdsReqLoading,
    })

    const checkBroWidth = (width) => {
        if (width > 600) setTreeX(256)
        else setTreeX(0)
    }


    useEffect(() => {
        checkBroWidth(window.innerWidth)
        window.addEventListener('resize', () => checkBroWidth(window.innerWidth))
    }, [])

    if (browser?.getBrowserName() === 'Safari'){
    // if (navigator?.vendor?.startsWith('Apple')){
        return <div className={'featureNotAvailable'}>
            {t('teamTree.notAvailable')}<a href="https://www.google.com/intl/en/chrome/">Google Chrome.</a>
        </div>;
    }

    if (!salesPackageIds && !salesPackageIdsReqLoading) {
        getSalesPackages();
    }

    if (!downlineData && !downlineQueryData && !downlineDataReqLoading && salesPackageIds) {
        getDownline({variables: {salespackage_ids: salesPackageIds.salesPackages?.map(x => x.id), toLvl: 2}});
    }

    if (downlineDataReqLoading || salesPackageIdsReqLoading) return <Loading/>

    if (downlineQueryData && !downlineData) {
        setDownlineData(JSON.parse(JSON.stringify(downlineQueryData)));
    }



    function setPartnerTreeWithDownline(data) {
        let meArray = [JSON.parse(JSON.stringify(data?.me))];
        let list = JSON.parse(JSON.stringify(meArray.concat(data?.me?.downline)));
        let map = {}
        let node = null
        let roots = [];

        for (let i = 0; i < list.length; i += 1) {
            map[list[i].id] = i;
            list[i].children = [];
        }

        for (let i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.user_referrer_id && list[map[node.user_referrer_id]]) {
                list[map[node.user_referrer_id]].children.push(node);
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    async function addNodePartnersToTree(node) {
        const {data} = await client.query({
            query: queries.GetDirectPartners,
            variables: {salespackage_ids: salesPackageIds.salesPackages?.map(x => x.id), user_id: node.id}
        });
        const downlineCopy = JSON.parse(JSON.stringify(downlineData));
        for (let newPartner of data?.direct_partners) {
            if (!downlineCopy.me.downline.find(x => x.id === newPartner.id)) {
                downlineCopy.me.downline.push(newPartner);
            }
        }
        setDownlineData(downlineCopy);
    }

    function deleteNodePartnersFromTree(node) {
        const downlineCopy = JSON.parse(JSON.stringify(downlineData));
        removeChildrenFromTree(node, downlineCopy)
        setDownlineData(downlineCopy);
    }

    function removeChildrenFromTree(node, list) {
        for (let nodeChildren of node.children) {
            if (nodeChildren.children.length > 0) {
                removeChildrenFromTree(nodeChildren, list)
            }
            const index = list.me.downline.findIndex(x => x.id === nodeChildren.id);
            if (index >= 0) {
                list.me.downline.splice(index, 1);
            }
        }
    }

    const showTree = (depth) => {
        return downlineData ? <Tree
            translate={{x: 50, y: 50}}
            pathFunc='step'
            orientation='vertical'
            data={setPartnerTreeWithDownline(downlineData)}
            depthFactor={200}
            pathClassFunc={() => 'custom-link'}
            renderCustomNodeElement={(rd3tProps) => {
                return Node(rd3tProps, depth);
            }}
        /> : null
    }
    const propTypes = {
        nodeData: PropTypes.object.isRequired
    }

    const Node = ({nodeDatum}) => {

        const isProfessionalPackage = nodeDatum.is_subscription_active;
        const packageName = isProfessionalPackage ? nodeDatum.currentSalesPackage?.name : 'Basic'
        const showTooltip = () => {
            return (
                <div className='tooltip' style={{background: 'white'}}>
                    <div className='tooltipContent'>
                        <ul>
                            <li>
                                <div style={{display: 'flex', flexDirection: 'row', margin: '5px'}}>
                                    <div><img alt="" className='nodeImage'
                                              src={nodeDatum?.profile_picture_url ? '/backend/partner-file/' + nodeDatum.profile_picture_url : photo_placeholder}/>
                                    </div>
                                    <div style={{margin: '10px'}}>
                                        <div>{nodeDatum.user_firstname}</div>
                                        <div>{nodeDatum.user_lastname}</div>
                                    </div>
                                </div>

                            </li>
                            <li>
                                <div>Direct partners:</div>
                                <div>{nodeDatum.directPartner} / PRO: {nodeDatum.activeDirectPartnerPro}</div>
                            </li>
                            <li>
                                <div>Indirect partners:</div>
                                <div>{nodeDatum.indirectPartner} / PRO: {nodeDatum.activeIndirectPartnerPro}</div>
                            </li>
                            <li>
                                <div>Total partners:</div>
                                <div>{nodeDatum.totalPartner} / PRO: {nodeDatum.activeTotalPartnerPro}</div>
                            </li>
                        </ul>
                        <div
                            className={isProfessionalPackage ? 'professionalPackage' : 'basicPackage'}>{packageName}</div>
                    </div>
                </div>
            )
        }
        return (
            <>
                <circle style={{display: 'none'}} r={45}></circle>
                <foreignObject className='nodeWrapper' width={80} height={120}>
                    <div style={{background: 'white', marginTop: '-50%', marginLeft: '-100%'}}>
                        <div>
                            <Tooltip title={showTooltip} color='#40b7e5'>
                                <img
                                    onClick={e => {
                                        e.preventDefault();
                                        showTooltip()
                                    }}
                                    alt="" className='nodeImage'
                                    src={nodeDatum?.profile_picture_url ? '/backend/partner-file/' + nodeDatum.profile_picture_url : photo_placeholder}/>
                            </Tooltip>
                            <p className={isProfessionalPackage ? 'nodeNameProfessional' : 'nodeNameBasic'}>{nodeDatum.user_firstname + ' ' + nodeDatum.user_lastname}</p>
                            {
                                nodeDatum.__rd3t.collapsed === false && nodeDatum.directPartner > 0 && nodeDatum.children.length > 0 ?
                                    <ImArrowUp className={isProfessionalPackage ? 'arrowProfessional' : 'arrowBasic'}
                                        onClick={() => deleteNodePartnersFromTree(nodeDatum)}
                                    /> : ''
                            }
                            {
                                nodeDatum.directPartner > 0 && nodeDatum.children.length === 0 ?
                                    <ImArrowDown className={isProfessionalPackage ? 'arrowProfessional' : 'arrowBasic'}
                                        onClick={async () => await addNodePartnersToTree(nodeDatum)}
                                    /> : ''
                            }
                        </div>
                    </div>
                </foreignObject>
            </>
        )
    }
    Node.propTypes = propTypes
    return (
        <div className='teamtree page'>
            <div className='lightCard treeWrapper'>
                {
                    showTree(2)
                }
            </div>
        </div>
    )
}
export default TeamTree
