import React, {useState, useEffect} from 'react'
import './style.scss'
import '../../nsw-frontend-library/styles/fields.scss'
import {useLazyQuery} from "@apollo/client";
import {getMainVideo, checkReferrer} from "./queries";
import Register from "../../nsw-frontend-library/components/auth/register"
import ReactPlayer from "react-player";
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import i18n from 'i18next';
import Swal from "sweetalert2";

const Registration = () => {
    const [language, setLanguage] = useState('');
    const [referrer, setReferrer] = useState('');
    const [getVideo, {data: video, loading: videoReqLoading}] = useLazyQuery(getMainVideo, {fetchPolicy: 'no-cache'});

    useEffect(() => {
        const sponsor = window.location.pathname.split('/');
        if (!sponsor) {
            window.location.href = '/backoffice/auth/login'
            return;
        }
        const ref = sponsor[sponsor.length - 1];
        if (!ref || ref === '') {
            window.location.href = '/backoffice/auth/login'
            return;
        }

        setReferrer(ref);
    }, []);

    i18n
        .on('languageChanged', (lng) => {
            if(lng !== language)
                setLanguage(lng);
        })

    useEffect(() => {
        getVideo();
    }, [language, getVideo]);


    let [checkRef, {loading, data}] = useLazyQuery(checkReferrer(referrer), {
        onCompleted: d => {
            if (!d.checkReferrer.result) {
                Swal.fire({icon: 'error', title: 'Error', text: d.checkReferrer.error, confirmButtonColor: '#FFA500'})
                window.location.href = '/backoffice/auth/login';
            }
        }
    })

    if (referrer && referrer !== '' && !loading && !data) {
        checkRef();
    }

    if (!video && !videoReqLoading) {
        getVideo();
    }

    if (videoReqLoading || !referrer) return <Loading/>

    console.log('initRegister');

    return <div className='registration'>
        {
            video?.mainVideo?.url ? <div className='lightCard register-video'>
                <ReactPlayer width='100%' height='100%' controls={true}
                             url={video.mainVideo.url}
                             playsinline  />
            </div> : null
        }
        <Register referrer={referrer}/>
    </div>
}

export default Registration
