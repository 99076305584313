import React, {useState} from 'react'
import './style.scss'
import '../../nsw-frontend-library/styles/global.scss'
import '../../nsw-frontend-library/styles/fields.scss'
import {Checkbox} from 'antd'
import {useHistory} from 'react-router-dom'
import {useApolloClient} from '@apollo/client'
import CookieConsent from "react-cookie-consent";
import {useTranslation} from 'react-i18next';

import * as queries from './queries'
import * as $ from "jquery";
import {trace} from "../../nsw-frontend-library/utils/messageHandler"
import Swal from "sweetalert2";
import {trimInput} from "../../nsw-frontend-library/utils/validations";

function validateData(data) {
    const INPUT_MAX_LEN = 100;
    const result = {success: true, missing: []};
    for (const [k, v] of Object.entries(data))
        if ((v.length > INPUT_MAX_LEN) || (v === '')) {
            result.success = false;
            result.missing.push(k);
        }
    return result;
}

const Login = () => {
    const {t} = useTranslation();
    const [acceptCookie, setAcceptCookie] = useState(true);
    const history = useHistory();
    const client = useApolloClient();
    const [inputsValue, setinputsValue] = useState({
        username: ''
    });

    trace(__filename, 'init');

    const changeValue = (val, id) => {
        setinputsValue({
            ...inputsValue,
            [id]: trimInput(val)
        })
    };
    const sendData = async () => {

        const validate = validateData(inputsValue);
        if (!validate.success) {
            for (const e of validate.missing)
                $('#' + e).css('border', '2px solid red');
            return;
        }
        const {data} = await client.query({query: queries.sendData(inputsValue), fetchPolicy: "no-cache"});
        Array.from(document.getElementsByClassName("ivpInput")).forEach(e => e.value = '');
        if (data.login.result) {
            localStorage.setItem('login', true);
            localStorage.setItem('isFirstPageSinceLogin', true);
            history.push('/')
        } else {
            localStorage.removeItem('login');
            Swal.fire({icon: 'error', title: 'Error', text: data.login.error, confirmButtonColor: '#FFA500'})
        }
    }
    const handleKeypress = event => {
        if (event.charCode === 13) {
            event.preventDefault();
            sendData();
        }
    }

    trace(__filename, 'before return');

    return (
            <div className='login' >
            <div className='margin-bot18 bold'
                 style={{width: '100%', color: 'orange', fontSize: '1.5em', fontWeight: 'bold'}}>
                {t('login_login')}
            </div>

            <input id="username" onChange={(e) => {
                $('#username').css('border', 'none');
                changeValue(e.target.value, 'username')
            }} onKeyPress={handleKeypress} placeholder={t('login_email')} type="text"
                   className='ivpInput transparentInput margin-bot10 ivpFormField'/>
            <input id="password" onChange={(e) => {
                $('#password').css('border', 'none');
                changeValue(e.target.value, 'password')
            }} onKeyPress={handleKeypress} placeholder={t('login_password')} type="password"
                   className='ivpInput transparentInput margin-bot10 ivpFormField'/>
            <Checkbox className='margin-bot10 checkboxlogin'>{t('login_rememberMe')}</Checkbox>
            <button onClick={() => {
                if (!acceptCookie)
                    Swal.fire({icon: 'error', title: 'Error', text: 'Accept cookies please', confirmButtonColor: '#FFA500'})
                else
                    sendData();
            }} className='orangeButton margin-bot10 ivpFormButton'>{t('login_login')}
            </button>
            <div onClick={() => history.push('/auth/forgot-Password')}
                 className='loginLinks'>{t('login_forgotPassword')}</div>
            <CookieConsent
                onAccept={() => {
                    setAcceptCookie(true);
                }}
                debug={true}
                buttonText={t('login_popup_confirm')}
            >
                {t('login_popup_content')}
            </CookieConsent>
        </div>
    )
}

export default Login
