import React, {useState} from "react";
import {Modal} from "antd";
import {IconContext} from "react-icons";
import {ImExit} from "react-icons/all";

export const Logout = (props) => {
    const { logoutCallback } = props
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const logout = () => {
        setIsModalVisible(false);
        logoutCallback();
    }

    return <div>
        <div className='lightCard headerLightCard exit-sec headerButton' id='logout-button' onClick={showModal}>
            <IconContext.Provider
                value={{size: '1.45em'}}>
                <ImExit/>
            </IconContext.Provider>
        </div>
        <Modal footer={null} visible={isModalVisible} okText="Yes" cancelText='No' onCancel={handleCancel}>
            <div className='modalCard'>
                <p> Are you sure you want to exit?</p>
                <div className='modalCard-buttonAlignment'>

                    <button className='modalCard-secondaryButton' id='logoutModal-No' onClick={handleCancel}>No</button>
                    <button className='modalCard-primaryButton' onClick={logout}>Yes</button>
                </div>
            </div>
        </Modal>
    </div>
}