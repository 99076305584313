import React, {useEffect} from 'react'
import './style.scss'
import Swal from 'sweetalert2';

const PaymentComplete = () => {
    useEffect(() => {
        const description = 'You will be redirected shortly...';

        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');
        if (status === 'success')
            Swal.fire({
                title: 'Thanks for your purchase!',
                text: description,
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
            })
        else
            Swal.fire({
                title: 'Purchase failed',
                text: description,
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: false,
            })
        setTimeout(() => window.location.href = '/backoffice', 3000);
    }, []);

    return (
        <div/>
    )
}
export default PaymentComplete
