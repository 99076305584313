import enTranslation from './en.json';
import deTranslation from './de.json';

export const languageResources = {
    en: {
        translation: enTranslation
    },
    de: {
        translation: deTranslation
    }
};