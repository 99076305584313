import React, {useState} from "react";
import {useTranslation} from 'react-i18next';
import { Drawer, Button } from 'antd';
import './languageSelector.scss';
import language_selector_icon from '../../assets/languageicon_new.png';

const LANGUAGES = [
    {'key': 'en', 'name': 'English'},
    {'key': 'de', 'name': 'Deutsch'}
]

export const LanguageSelector = (props) => {
    const [visible, setVisible] = useState();
    const {i18n, t} = useTranslation();
    const {lngStyle} = props;
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setVisible(false);
    }
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const basestyle = {width: '35px', background: 'transparent', verticalAlign: 'baseline', filter: 'invert()', border: 'none'}
    const styles = lngStyle || basestyle;

    return (
        <div className="selectLanguageContainer" id='lngSelect'>
            <img src={language_selector_icon} className="lngSelectImage"  style={styles} onClick={showDrawer}  alt=""/>
            <Drawer
                title={t('language_selector_title')}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                    {LANGUAGES.map(v => {
                        return <div><Button key={v.key} onClick={e => changeLanguage((v.key))}>{v.name}</Button></div>
                    })}
            </Drawer>
        </div>
    )
}
