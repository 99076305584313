import {gql} from '@apollo/client'

export const verifyToken = token => {
    const query = `
    query {
        verifyToken(
            token : "${token}"
        )
    }
    `
    return gql`${query}`;
}

export const resetPassword = data => {
    const query = `
    query {
        resetPassword(
            ${Object.keys(data).map(val => `${val} : ${JSON.stringify(data[val])}`)}
        ){
            result
            error
        }
    }
    `
    return gql`${query}`;
}
