import Swal from "sweetalert2";

export function showSuccessfulMessage(text = null, title = 'Done', writeToLog = false) {
    writeToLog && console.log(text);
    Swal.fire({icon: 'success', title, text, confirmButtonColor: '#FFA500'});
}

export function showErrorMessage(text = null, title = 'Error', writeToLog = false, sendToRaven = false) {
    writeToLog && console.log(text);
    Swal.fire({icon: 'error', title, text, confirmButtonColor: '#FFA500'});
    sendToRaven && console.log('Implement feature');
}

export function trace(){
    console.log.apply(this, arguments);
}