import {gql} from '@apollo/client'

export const GetUserProfileInfo = gql`
query {
    me {
      profile_picture_url
      user_title
      user_firstname
      user_lastname
      user_birthday
      language{
        id
      }
      company_name
      user_uid
      created_at
      user_phone
      user_email
      user_address
      user_country{
        id
      }
      user_zipcode
      user_city
      user_state
      user_bank_name
      user_account_holder
      user_iban
      user_bic
      sponsor{
        user_name
        id
        user_phone
        user_email
      }
      share_data
    }
  }
`

export const updateProfile = data => {
    const mutation = `
    mutation {
        updateProfile(
            ${Object.keys(data)?.map(val => `${val} : "${data[val]}"`)}
        )
    }
    `
    return gql`${mutation}`
}

export const validatePassword = pw => {
    const query = `
    query {
        validatePassword(
            password: "${pw}"
        ){
            result
            error
        }
    }
    `
    return gql`${query}`
}
