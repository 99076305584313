import {gql} from '@apollo/client'

export const checkReferrer = referrer => {
    const query = `
    query {
        checkReferrer(
            referrer: "${referrer}"
        ){
            result
            error
        }
    }
    `
    return gql`${query}`;
}

export const getMainVideo = gql`
query {
  mainVideo{
    url
  }
}
`

export const getTermsAndConditions = gql`
query {
  termsAndConditions{
    url
  }
}
`

export const getRecommenderAgreement = gql`
query {
  recommenderAgreement{
    url
  }
}
`
