import {useApolloClient} from "@apollo/client";
import React, {useEffect, useState} from "react";
import * as $ from "jquery";
import {getCountryList} from "../../../../storage/storage";
import {Col, Row} from "antd";
import {register} from "../authQueries"
import '../../cards/cardStyles.scss'
import '../authStyles.scss'
import {validation, ValidationCheckbox, ValidationInputField, ValidationSelect} from "../../fields/validations";
import {isValidMail, isValidPassword, maxLength100, comparePassword, trimInput} from "../../../utils/validations";
import {useTranslation} from 'react-i18next';
// import {LanguageSelector} from '../../buttons';
import {showSuccessfulMessage, showErrorMessage} from "../../../utils/messageHandler";
import * as registerQueries from "../../../../pages/register/queries";
import {useLazyQuery} from "@apollo/client";
import {Loading} from "../../utils/loadingPageScreen";
const _ = require('lodash');

const Register = (props) => {
    const {t} = useTranslation();
    const {referrer} = props;
    const client = useApolloClient();
    const [getTermsAndConditions, {
        data: termsAndConditions,
        loading: termsAndConditionsLoading
    }] = useLazyQuery(registerQueries.getTermsAndConditions);
    const [getRecommenderAgreement, {
        data: recommenderAgreement,
        loading: recommenderAgreementLoading
    }] = useLazyQuery(registerQueries.getRecommenderAgreement);
    const [countries, setCountries] = useState([]);
    const [inputValues, setInputValue] = useState({
        email: {value: '', valid: false, messages: [], callbackVariable: 'email', validations: [isValidMail]},
        password: {
            value: '',
            valid: false,
            messages: [],
            callbackVariable: 'password',
            validations: [isValidPassword, maxLength100]
        },
        confirmPassword: {
            value: '',
            valid: false,
            messages: [],
            callbackVariable: 'confirmPassword',
            validations: [isValidPassword, maxLength100]
        },
        firstname: {value: '', valid: false, messages: [], callbackVariable: 'firstname', validations: [maxLength100]},
        lastname: {value: '', valid: false, messages: [], callbackVariable: 'lastname', validations: [maxLength100]},
        username: {value: '', valid: false, messages: [], callbackVariable: 'username', validations: [maxLength100]},
        country: {value: '', valid: false, messages: [], callbackVariable: 'country', validations: []},
        agbCheck: {value: true, valid: true, messages: [], callbackVariable: 'agbCheck', validations: []},
        ageCheck: {value: true, valid: true, messages: [], callbackVariable: 'ageCheck', validations: []},
        shareData: {
            value: true,
            valid: true,
            messages: [],
            callbackVariable: 'shareData',
            validations: [],
            isMandatory: false
        }
    })

    useEffect(() => {
        async function countryList() {
            setCountries(await getCountryList(client));
        }

        countryList();
    }, [client])

    if (!termsAndConditions && !termsAndConditionsLoading) {
        getTermsAndConditions();
    }

    if (!recommenderAgreement && !recommenderAgreementLoading) {
        getRecommenderAgreement();
    }

    if (termsAndConditionsLoading || recommenderAgreementLoading) return <Loading/>

    const sendData = async () => {
        console.log(inputValues);
        let fieldsValid = true;
        for (const v of Object.values(inputValues)) {
            if (v.hasOwnProperty('valid') && !v.valid) {
                console.log(v);
                fieldsValid = false;
                break;
            }
        }

        if (!fieldsValid) {
            for (let field in inputValues) {
                validation(inputValues[field], {callback: changeValue});
            }
            additionalValidation();
            return;
        }

        if (!additionalValidation()) {
            return;
        }

        function additionalValidation() {
            const compareResult = comparePassword(inputValues.password.value, inputValues.confirmPassword.value);
            if (!compareResult?.valid) {
                changeValue(inputValues.confirmPassword.value, inputValues.confirmPassword.callbackVariable, compareResult.valid, [compareResult.errorMessage], false);
                return false;
            }
            return true;
        }

        console.log('sendData');

        const {data} = await client.query({
            query: register, variables: {
                referrer: referrer,
                email: inputValues.email.value,
                password: inputValues.password.value,
                confirmPassword: inputValues.confirmPassword.value,
                firstname: inputValues.firstname.value,
                lastname: inputValues.lastname.value,
                username: inputValues.username.value,
                country: inputValues.country.value,
                agbCheck: inputValues.agbCheck.value,
                ageCheck: inputValues.ageCheck.value,
                shareData: inputValues.shareData.value
            }, fetchPolicy: "no-cache"
        });

        if (data)
            if (data && data.register.result === true) {
                showSuccessfulMessage(t('register_message_verification_mail_sent'));
                //setTimeout(() => window.location.href = '/backoffice', 2000);
                $("#registerFormSubmit").attr('disabled', 'false');
            } else {
                showErrorMessage(data.register.error);
                $("#registerFormSubmit").attr('disabled', 'false');
            }
    }

    const changeValue = (val, id, valid, messages, overwriteMessages = true) => {
        let newInputValue = _.clone(inputValues);
        newInputValue[id].value = trimInput(val);

        if (overwriteMessages || (newInputValue[id].valid === true && !newInputValue[id].messages?.length > 0)) {
            messages !== null && messages !== undefined && (newInputValue[id].messages = messages);
        }
        valid !== null && valid !== undefined && (newInputValue[id].valid = valid);

        setInputValue(newInputValue);
    }

    return (
        <div className='register'>
            {/*<LanguageSelector/>*/}
            <Row gutter={[12, 12]}>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
                    <ValidationInputField callback={changeValue}
                                          inputValue={inputValues.email} styles='register-field'
                                          placeholder={t('register_email')}
                                          id='register-email' inputType='input'/>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
                    <ValidationInputField callback={changeValue}
                                          inputValue={inputValues.username} styles='register-field'
                                          placeholder={t('register_username')}
                                          id='register-username' inputType='input'/>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
                    <ValidationInputField callback={changeValue}
                                          inputValue={inputValues.firstname} styles='register-field'
                                          placeholder={t('register_firstname')}
                                          id='register-firstname' inputType='input'/>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
                    <ValidationInputField callback={changeValue}
                                          inputValue={inputValues.lastname} styles='register-field'
                                          placeholder={t('register_lastname')}
                                          id='register-lastname' inputType='input'/>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
                    <ValidationInputField callback={changeValue}
                                          inputValue={inputValues.password} styles='register-field'
                                          placeholder={t('register_password')}
                                          id='register-password' inputType='password'/>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
                    <ValidationInputField callback={changeValue}
                                          inputValue={inputValues.confirmPassword} styles='register-field'
                                          placeholder={t('register_confirmPassword')}
                                          id='register-confirmPassword' inputType='password'/>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}>
                    <ValidationSelect callback={changeValue} styles='register-selection'
                                      inputValue={inputValues.country}
                                      placeholder={t('register_country')}
                                      id='register-valMod' options={countries?.map(rec =>
                        <option key={rec.id} value={rec.name + '_' + rec.id}>{rec.name}</option>)}/>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}>
                    <ValidationCheckbox callback={changeValue} styles='register-checkbox-div'
                                        inputValue={inputValues.shareData} id='register_checkbox_shareData'
                                        checkboxText={t('register_checkbox_shareData')} isMandatory='false'/>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}>
                    <ValidationCheckbox callback={changeValue} styles='register-checkbox-div'
                                        inputValue={inputValues.ageCheck} id='register_checkbox_age'
                                        checkboxText={t('register_checkbox_age')}/>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}>
                    <ValidationCheckbox callback={changeValue} styles='register-checkbox-div'
                                        inputValue={inputValues.agbCheck} id='register_checkbox_agb'
                                        checkboxText={<span>{t('register_checkbox_gtc_accept')} <a
                                            href={termsAndConditions?.termsAndConditions?.url}>{t('register_checkbox_gtc')}</a> {t('register_checkbox_gtc_and')}
                                            <a href={recommenderAgreement?.recommenderAgreement?.url}>{t('register_checkbox_gtc_recommenderAgreement')}</a></span>}/>
                </Col>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}>
                    <button
                        className={'orangeButton ivpFormButton'}
                        id="registerFormSubmit"
                        onClick={sendData}>{t('register_register')}
                    </button>
                </Col>
            </Row>
        </div>
    )
}

export default Register
