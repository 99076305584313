Object.assign(module.exports, {
    /*TEST locally*/
    // STRIPE_PUBLIC_KEY: 'pk_test_51IDy7IHFSAkBNJXJKle2mvp3rxTOMXTt0O7cNMTHHyzek8uTIBnWNivhWPN78ior9IgYS1jD2qjAh4rYJXYtwZaa00LQphBsBP',
    // SUCCESS_PAYMENT_REDIRECT_URL: 'http://georg.invite.pro/backoffice/payment-complete?status=success',
    // FAILURE_PAYMENT_REDIRECT_URL: 'http://georg.invite.pro/backoffice/payment-complete?status=failed',
    /*----*/

    /*LIVE*/
    STRIPE_PUBLIC_KEY: 'pk_live_51IDy7IHFSAkBNJXJHMbBHATCUj0g8hOa7aPNOuHMkxSvtLo8T1GAeKM8Qn9e2aYDUTTt82gKzcd397obNFY4WJ23009IMLkbsJ',
    SUCCESS_PAYMENT_REDIRECT_URL: 'https://invite.pro/backoffice/payment-complete?status=success',
    FAILURE_PAYMENT_REDIRECT_URL: 'https://invite.pro/backoffice/payment-complete?status=failed',
    /*----*/

    MENU_ITEM_CSS_CLASSES: {
        dashboard: '.menuitemDashboard',
        editProfile: '.menuitemEditProfile',
        shop: '.menuitemShop',
        sessions: '.menuitemSessions',
        team: '.menuitemTeam',
        teamTree: '.menuitemTeamTree',
        commissions: '.menuitemCommissions',
        invoices: '.menuitemInvoices',
        share: '.menuitemShare',
    },
    GUTTER_SPACE: 16
})
