import {gql} from '@apollo/client'

export const validateToken = token => {
    const query = `
    query {
        resetEmail(
            token: "${token}"
        ){
            result
            error
        }
    }
    `
    return gql`${query}`;
}
