import React from 'react'
import './style.scss'
import '../../../nsw-frontend-library/styles/global.scss'
import invitepro from '../../../assets/images/invitepro.png'
import {Row, Col} from 'antd'
import {LanguageSelector} from "../../../nsw-frontend-library/components/buttons/index";
const styles = {
    width: '35px',
    background: 'transparent',
    verticalAlign: 'baseline',
    border: 'none',
    position: 'absolute',
    // position: 'fixed',
    right: 0,
    top: 0,
    marginRight: '2%',
    marginTop: '1.5%',
}
const GuestLayout = ({children}) => {
    return (
            <div className='guestLayout'>
                <LanguageSelector lngStyle={styles} />
                <Row justify='center' style={{height: '100%'}}>
                <Col xs={{span: 24}} sm={{span: 20}} md={{span: 20}} lg={{span: 16}} xl={{span: 12}}>
                    <div className='cardWrapper'>
                        <div className='guestCard'>
                            <a href="/" className='loginLogo'>
                                <div className="logowrapper">
                                    <img src={invitepro} alt=""/>
                                </div>
                            </a>
                            {children}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default GuestLayout
