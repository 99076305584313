import {gql} from '@apollo/client'

export const forgotPassword = gql`
query($email: String!) {
    requestResetPassword(email: $email){
        result
        error
    }
}
`

export const validateToken = gql`
query($emailToken: String!) {
    completeRegistration(emailToken: $emailToken){
        result
        error
    }
}
`

export const register = gql`
query(
    $referrer: String!, 
    $email: String!, 
    $password: String!, 
    $confirmPassword: String!, 
    $firstname: String!,
    $lastname: String!, 
    $username: String!, 
    $country: String!, 
    $agbCheck: Boolean!, 
    $ageCheck: Boolean!, 
    $shareData: Boolean!
){
    register(
        referrer: $referrer,
        email: $email,
        password: $password,
        confirmation: $confirmPassword,
        firstname: $firstname,
        lastname: $lastname,
        username: $username,
        country_id: $country,
        agbCheck: $agbCheck,
        ageCheck: $ageCheck,
        shareData: $shareData
    ){
        result
        error
    }
}
`
