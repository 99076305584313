import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {languageResources} from './nsw-frontend-library/locales/languageResource'

const selected_lng = (document.cookie
    ?.split('; ')
    ?.find(row => row.startsWith('lng='))
    ?.split('=')?.[1]) || 'en';

i18n
    .on('languageChanged', (lng) => {
        document.cookie = 'lng=' + lng + '; expires=' + (new Date((new Date()).getFullYear() + 10, 1, 1)).toUTCString() + '; path=/';
    })
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: languageResources,
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false
        },
        lowerCaseLng: true,
        lng: selected_lng
    });

export default i18n;
