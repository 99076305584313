import React, {useState} from 'react'
import './style.scss'
import './../../style.scss'
import '../../nsw-frontend-library/styles/table.scss'
import {Col, Row, Select, Table, Input} from 'antd'
import {VscFilePdf} from 'react-icons/all'
import {useLazyQuery} from '@apollo/client'
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import * as queries from './queries'
import * as defaultQueries from '../../nsw-frontend-library/queries/queries'
import moment from "moment";
import {search} from "ss-search"
import {GUTTER_SPACE} from '../../utilities/constants'
import {useTranslation} from "react-i18next";

function addTableColumn(columns, columnKey, columnName, filterValues, onFilter) {
    columns.push({
        title: columnName,
        dataIndex: columnKey,
        filters: filterValues,
        onFilter: onFilter
    })
}

const Commissions = () => {
    const {t} = useTranslation();
    const [selectedTimeRange, setTimeRange] = useState();
    const [filterText, setFilterText] = useState();
    const [getTimeRanges, {
        data: timeRangeInfo,
        loading: timeRangeInfoLoading
    }] = useLazyQuery(defaultQueries.getTimeRangesQuery)
    const [getCommissions, {
        data: commissionsInfo,
        loading: commissionsInfoReqLoading
    }] = useLazyQuery(queries.getCommissionsQuery, {
        skip: timeRangeInfoLoading,
        fetchPolicy: "no-cache",
    })
    if (!timeRangeInfo && !timeRangeInfoLoading) {
        getTimeRanges();
    }

    if (!commissionsInfo && !commissionsInfoReqLoading && timeRangeInfo) {
        getCommissions();
    }

    if (commissionsInfoReqLoading || timeRangeInfoLoading) return <Loading/>
    let commissions = commissionsInfo?.me?.commissions ? JSON.parse(JSON.stringify(commissionsInfo?.me?.commissions)) : [];
    commissions.forEach(a => {
        a.commissionType = 'PRO Package Level ' + a.level;
        a.payoutOn = moment(a.created_at, 'MM/DD/yyyy').endOf('month').format('MM/DD/yyyy');
        a.from_proponent_name = a?.from_proponent?.user_name;
        a.is_confirmed = a.is_confirmed ? <div style={{color: 'green'}}>completed</div> : <div style={{color: 'orange'}}>pending</div>;
    });

    const searchKeys = ["created_at", "commissionType", "from_proponent_name", "amount", "payoutOn"];
    const filteredCommissions = search(commissions, searchKeys, filterText);

    let columns = [];
    addTableColumn(columns, 'created_at', t('commissions.table.date'));
    addTableColumn(columns, 'commissionType', t('commissions.table.type'));
    addTableColumn(columns, 'from_proponent_name', t('commissions.table.through'));
    addTableColumn(columns, 'amount', t('commissions.table.amount'));
    addTableColumn(columns, 'payoutOn', t('commissions.table.payoutDate'));
    addTableColumn(columns, 'is_confirmed', t('commissions.table.status'));

    const DarkCard = (props) => {
        const {text, digit, pdfUrl} = props
        return (
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}} xl={{span: 6}}>
                <div className='darkCard commission-darkCard'>
                    <div className='commission-darkCard-text'>{text}</div>
                    <div className='commission-darkCard-price'>
                        <div className='commission-darkCard-digit'>{digit}</div>
                        {
                            pdfUrl ? <a href="http://"><VscFilePdf className='commission-darkCard-pdfIcon'/></a> : ''
                        }
                    </div>
                </div>
            </Col>
        )
    }

    return (
        <div className='commission'>
            <Row gutter={[GUTTER_SPACE, GUTTER_SPACE]}>
                <DarkCard text={t('commissions.header.orders.direct')}
                          digit={(commissionsInfo?.me?.commissions?.filter(c => c.level === 1)?.reduce((prev, next) => prev + next.amount, 0) || 0).toFixed(2) + ' €'}/>
                <DarkCard text={t('commissions.header.orders.indirect')}
                          digit={(commissionsInfo?.me?.commissions?.filter(c => c.level === 2)?.reduce((prev, next) => prev + next.amount, 0) || 0).toFixed(2) + ' €'}/>
                <DarkCard text={t('commissions.header.stars')} digit=' 0,00 €'/>
                <DarkCard text={t('commissions.header.total')}
                          digit={(commissionsInfo?.me?.commissions?.reduce((prev, next) => prev + next.amount, 0) || 0).toFixed(2) + ' €'}/>
            </Row>
            <Row gutter={[GUTTER_SPACE, GUTTER_SPACE]}>
                <Col>
                    <div className='lightCard card100PercentHeight'>
                        <div className='lightCard-filter'>
                            <div className='lightCard-filter-text'>{t('commissions.month')}</div>
                            <Select className='lightCard-inputTextBox' id='timeRangesCommission'
                                    defaultValue={selectedTimeRange || t('commissions.monthDefault')}
                                    onChange={(e) => {
                                        setTimeRange(e)
                                        getCommissions({variables: {timerange_id: e}})
                                    }}>
                                {timeRangeInfo?.me?.active_timeranges?.map(rec => <option key={rec.id}>{rec.name}</option>)}
                            </Select>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='lightCard card100PercentHeight'>
                        <div className='lightCard-filter'>
                            <div className='lightCard-filter-text'></div>
                            <Input id='commissionFilter' className='lightCard-inputTextBox' placeholder={t('commissions.search') + '…'}
                                   onChange={e => {
                                       setFilterText(e.target.value);
                                   }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='lightTable'>
                <Table columns={columns} dataSource={filteredCommissions} pagination={{defaultPageSize: 15}}/>
            </div>
        </div>
    )
}
export default Commissions
