import React, {Suspense} from 'react'
import './style.scss'
import Client from './client'

function App() {
  return (
      <Suspense fallback={null}>
    <div className="App">
      <Client />
    </div>
      </Suspense>
  );
}

export default App;
