import * as defaultQueries from "./queries";

export function setIntoLocalStorage(key, value) {
    try {
        const data = {value, now: Date.now()};
        localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
        console.log(e);
    }
}

export function getFromLocalStorage(key, maxAgeSeconds = 60 * 60 * 24) {
    try {
        const item = localStorage.getItem(key);
        const data = JSON.parse(item);
        const age = Date.now() - data.now;
        if (age > maxAgeSeconds * 1000)
            return null;
        return data.value;
    } catch (e) {
        return null;
    }
}

export const getCountryList = async (client) => {
    const storedCountries = getFromLocalStorage('countries', 60 * 60 * 24 * 7);
    if(storedCountries){
        const filteredCountryList = JSON.parse(JSON.stringify(storedCountries));
        const sortedList = filteredCountryList.filter(x => x.name).sort((a, b) => a.name.localeCompare(b.name));
        return sortedList;
    }

    const {data: countryData} = await client.query({query: defaultQueries.GetCountries});

    if(!countryData?.countries){
        return;
    }

    const filteredCountryList = JSON.parse(JSON.stringify(countryData.countries));
    const sortedList = filteredCountryList.filter(x => x.name).sort((a, b) => a.name.localeCompare(b.name));

    setIntoLocalStorage('countries', sortedList);
    return sortedList;
}