import {useState, useEffect} from 'react'
import {useApolloClient} from "@apollo/client";
import {verifyToken, resetPassword} from './queries'
import Swal from "sweetalert2";
import {trimInput} from "../../nsw-frontend-library/utils/validations";

const ResetPW = () => {
    const client = useApolloClient();
    const [inputValue, setInputValue] = useState({})

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams) {
            Swal.fire({icon: 'error', title: 'Error', text: 'Invalid token', confirmButtonColor: '#FFA500'})
            return;
        }
        const t = urlParams.get('token')
        if (!t) {
            Swal.fire({icon: 'error', title: 'Error', text: 'Invalid token', confirmButtonColor: '#FFA500'})
            return;
        }
        checkToken(t);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const checkToken = async (t) => {
        debugger;
        const {data} = await client.query({query: (verifyToken(t)), fetchPolicy: "no-cache"});
        if (data)
            if (data && data.verifyToken.result === true) {
                changeValue(data.verifyToken.user_id, 'userId');
            } else
                window.location.href = '/backoffice/auth/login';
    }


    const sendData = async () => {
        debugger;
        if (inputValue['user_id'] === '' || inputValue['password'] === '' || inputValue['confirmpassword'] === '' || inputValue['password'] !== inputValue['confirmpassword']) {
            Swal.fire({icon: 'error', title: 'Error', text: 'Missing or invalid email', confirmButtonColor: '#FFA500'})
            return;
        }

        const {data} = await client.query({query: (resetPassword(inputValue)), fetchPolicy: "no-cache"});
        if (data) {

            debugger;
            if (data && data.resetPassword.result === true) {
                Swal.fire({icon: 'success', title: 'Done', text: 'Password has been reset!', confirmButtonColor: '#FFA500'})
                setTimeout(() => window.location.href = '/backoffice/auth/login', 5000);
            } else {
                Swal.fire({icon: 'error', title: 'Error', text: data.resetPassword.error, confirmButtonColor: '#FFA500'})
            }
        }

    }
    const changeValue = (val, id) => {
        setInputValue({
            ...inputValue,
            [id]: trimInput(val)
        })
    }
    return (
        <div className='emailCheck'>
            <div className='margin-bot18 bold'>Reset password</div>
            <div className='buttonWithLabel margin-bot18'>
                <label htmlFor="email" style={{display: 'none'}}>New password</label>
                <input className='ivpInput transparentInput margin-bot10 ivpFormField' placeholder='New password'
                       onChange={e => changeValue(e.target.value, 'password')} id='password' type="password"/>
            </div>
            <div className='buttonWithLabel margin-bot18'>
                <label htmlFor="email" style={{display: 'none'}}>Confirm new password</label>
                <input className='ivpInput transparentInput margin-bot10 ivpFormField'
                       placeholder='Confirm new password' onChange={e => changeValue(e.target.value, 'confirmpassword')}
                       id='confirmpassword' type="password"/>
            </div>
            <button type="submit" onClick={e => {
                e.preventDefault();
                sendData()
            }} className='orangeButton ivpFormButton'>Reset password
            </button>
        </div>
    )
}

export default ResetPW
