import React, {useState, useEffect} from 'react'
import './style.scss'
import {validateToken} from './queries'
import {useLazyQuery} from "@apollo/client";
import {Loading} from "../../nsw-frontend-library/components/utils/loadingPageScreen";
import Swal from "sweetalert2";

const ResetEmail = () => {
    const [token, setToken] = useState('')

    let [checkToken, {loading}] = useLazyQuery(validateToken(token), {
        onCompleted: d => {
            debugger;
            if (d.resetEmail.error)
                Swal.fire({icon: 'error', title: 'Error', text: d.resetEmail.error, confirmButtonColor: '#FFA500'})
            if (d.resetEmail.result)
                Swal.fire({icon: 'success', title: 'Done', text: 'Email has been reset!', confirmButtonColor: '#FFA500'})
            setTimeout(() => window.location.href = '/backoffice/auth/login', 5000);
        },
        onError: e => {
            console.log(e);
        }
    });

    useEffect(() => {
        debugger;
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams) {
            Swal.fire({icon: 'error', title: 'Error', text: 'Invalid token', confirmButtonColor: '#FFA500'})
            return;
        }
        const t = urlParams.get('token')
        if (!t) {
            Swal.fire({icon: 'error', title: 'Error', text: 'Invalid token', confirmButtonColor: '#FFA500'})
            return;
        }
        setToken(t);
        checkToken();
    }, [checkToken]);

    if (loading) return <Loading/>

    return (
        <div>
        </div>
    )
}

export default ResetEmail
