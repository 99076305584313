import {gql} from '@apollo/client'

export const getCommissionsQuery = gql`
query($timerange_id: String){
  me {
    commissions(timerange_id: $timerange_id){
      created_at
      commission_type
      level 
      from_proponent {
        user_name
      }
      amount
      is_confirmed
    }
  }
}
`
