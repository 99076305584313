import React, {useState} from 'react'
import {Switch, Redirect} from "react-router-dom"
import * as Pages from '../pages'
import {GlobalUserLayout, ContentUserLayout, UserRoute, Menu, Header} from '../components'
import {useLazyQuery} from "@apollo/client";
import {loggedIn} from "./queries";
import {Loading} from "../nsw-frontend-library/components/utils/loadingPageScreen";

const REDIRECT_URL = '/auth/login'
const UserRouter = () => {
    const [visibleDrawer, setVisibleDrawer] = useState(false)
    const [getLoggedIn, {
        data: isLoggedIn,
        loading: loggedInLoading
    }] = useLazyQuery(loggedIn, {fetchPolicy: "no-cache"})

    if (!isLoggedIn && !loggedInLoading)
        getLoggedIn();


    if (!isLoggedIn || loggedInLoading)
        return <Loading/>

    const toggleDrawer = () => {
        setVisibleDrawer(!visibleDrawer)
    }

    if (isLoggedIn.loggedIn !== true) {
        localStorage.removeItem('login');
        return <Redirect
            to={{
                pathname: REDIRECT_URL,
            }}
        />;
    } else {
        localStorage.setItem('login', true);
        return <GlobalUserLayout>
            <Menu toggleDrawer={toggleDrawer} visibleDrawer={visibleDrawer}/>
            <ContentUserLayout>
                <Header toggleDrawer={toggleDrawer}/>
                <Switch>
                    <UserRoute exact path='/media-center'> <Pages.MediaCenter/> </UserRoute>
                    <UserRoute exact path='/shop'> <Pages.Shop/> </UserRoute>
                    <UserRoute exact path='/payment-complete'> <Pages.PaymentComplete/> </UserRoute>
                    <UserRoute exact path='/support'> <Pages.Support/> </UserRoute>
                    <UserRoute exact path='/edit-profile'> <Pages.EditProfile/> </UserRoute>
                    <UserRoute exact path='/reset-email'> <Pages.ResetEmail/> </UserRoute>
                    <UserRoute exact path='/team-tree'> <Pages.TeamTree/> </UserRoute>
                    <UserRoute exact path='/team'> <Pages.Team/> </UserRoute>
                    <UserRoute exact path='/feedback'> <Pages.Feedback/> </UserRoute>
                    <UserRoute exact path='/share'> <Pages.Share/> </UserRoute>
                    <UserRoute exact path='/invoices'> <Pages.Invoices/> </UserRoute>
                    <UserRoute exact path='/commissions'> <Pages.Commissions/> </UserRoute>
                    {/*<UserRoute exact path='/file-uploader'> <Pages.FileUploader/> </UserRoute>*/}
                    <UserRoute exact path='/'> <Pages.Dashboard/> </UserRoute>
                    {/*<UserRoute exact path='/'> <Pages.Home/> </UserRoute>*/}
                </Switch>
            </ContentUserLayout>
        </GlobalUserLayout>
    }
}

export default UserRouter
