import React from 'react'
import {Redirect, Switch} from "react-router-dom"
import {GuestRoute, GuestLayout} from '../components'
import * as Pages from '../pages'
import {loggedIn} from "./queries";
import {Loading} from "../nsw-frontend-library/components/utils/loadingPageScreen";
import {useLazyQuery} from "@apollo/client";
import {trace} from "../nsw-frontend-library/utils/messageHandler"

const REDIRECT_URL = '/'
const GuestRouter = () => {
    const [getLoggedIn, {
        data: isLoggedIn,
        loading: loggedInLoading
    }] = useLazyQuery(loggedIn, {fetchPolicy: "no-cache"})

    trace(__filename, 'init');

    if (!isLoggedIn && !loggedInLoading)
        getLoggedIn();

    if (!isLoggedIn)
        return <Loading/>

    if (isLoggedIn.loggedIn === true) {
        trace(__filename, 'go to dashboard');
        localStorage.setItem('login', true);
        return <Redirect
            to={{
                pathname: REDIRECT_URL,
            }}
        />;
    } else {
        trace(__filename, 'go to login page');
        localStorage.removeItem('login');
        return <GuestLayout>
            <Switch>
                <GuestRoute exact path='/auth/forgot-Password'> <Pages.ForgotPassword/> </GuestRoute>
                <GuestRoute exact path='/auth/reset-password'> <Pages.ResetPassword/> </GuestRoute>
                <GuestRoute path='/ref'> <Pages.Register/> </GuestRoute>
                <GuestRoute path='/auth/verify'> <Pages.RegisterEmailConfirmation/> </GuestRoute>
                <GuestRoute exact path='/auth/login'> <Pages.Login/> </GuestRoute>
            </Switch>
        </GuestLayout>
    }
}

export default GuestRouter
