import React, {useState, useEffect} from 'react'
import './style.scss'
import {checkProStatus} from './queries'
import {Menu, Drawer} from 'antd'
import {useHistory} from "react-router-dom"
import {useApolloClient} from '@apollo/client'
import {setIntoLocalStorage} from '../../storage/storage'
import photo_placeholder from '../../nsw-frontend-library/assets/photo_placeholder.jpg';
import {
    NotificationOutlined,
    GoldOutlined,
    PlaySquareOutlined,
    MessageOutlined,
    DashboardOutlined,
    CommentOutlined,
    TeamOutlined,
    UserOutlined,
    ApartmentOutlined,
    ProfileOutlined,
    FireOutlined
} from '@ant-design/icons'
import {useTranslation} from "react-i18next";

const pageRoutes = [
    {
        icon: <DashboardOutlined/>,
        key: "dashboard",
        text: 'menu.dashboard',
        route: '/'
    },
    {
        icon: <UserOutlined/>,
        key: "edit-profile",
        text: 'menu.profile',
        route: '/edit-profile'
    },
    {
        icon: <FireOutlined/>,
        key: "shop",
        text: 'menu.shop',
        route: '/shop'
    },
    {
        icon: <CommentOutlined/>,
        key: "sessions",
        text: 'menu.sessions',
        route: '/sessions',
        isProfessional: true

    },
    {
        icon: <TeamOutlined/>,
        key: "team",
        text: 'menu.team',
        route: '/team'
    },
    {
        icon: <ApartmentOutlined/>,
        key: "team-tree",
        text: 'menu.teamTree',
        route: '/team-tree',
        isProfessional: true
    },
    {
        icon: <GoldOutlined/>,
        key: "commissions",
        text: 'menu.commissions',
        route: '/commissions'
    },
    {
        icon: <ProfileOutlined/>,
        key: "invoices",
        text: 'menu.invoices',
        route: '/invoices'
    },
    {
        icon: <NotificationOutlined/>,
        key: "share",
        text: 'menu.share',
        route: '/share'
    },
    {
        icon: <MessageOutlined/>,
        key: "feedback",
        text: 'menu.feedback',
        route: '/feedback'
    },
    {
        icon: <PlaySquareOutlined/>,
        key: "media-center",
        text: 'menu.mediacenter',
        route: '/media-center'
    }
    // ,
    // {
    //     icon: <PlaySquareOutlined/>,
    //     key: "file-uploader",
    //     text: 'File Uploader',
    //     route: '/file-uploader'
    // },
]

const LeftMenu = (props) => {
    const [menuStatus, setMenuStatus] = useState(null)
    useEffect(() => {
        checkBroWidth(window.innerWidth)
        window.addEventListener('resize', () => checkBroWidth(window.innerWidth))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const checkBroWidth = (width) => {
        if (width > 600) setMenuStatus(<MenuComp togStatus={false} toggleDrawer={props.toggleDrawer}/>)
        else setMenuStatus(null)
    }
    return (
        <>
            {menuStatus}
            <DrawerCom
                toggleDrawer={props.toggleDrawer}
                visibleDrawer={props.visibleDrawer}
            ><MenuComp togStatus={true} toggleDrawer={props.toggleDrawer}/></DrawerCom>
        </>
    )
}

const DrawerCom = (props) => {
    return (
        <>
            <Drawer
                placement='left'
                closable={false}
                onClose={props.toggleDrawer}
                visible={props.visibleDrawer}
            >
                {props.children}
            </Drawer>
        </>
    )
}

let user = {}

const MenuComp = (props) => {
    const {t} = useTranslation();
    const client = useApolloClient();
    const [dummy, setDummy] = useState(false);
    let history = useHistory()
    const isPro = JSON.parse(localStorage.getItem('isPro'))
    const {togStatus, toggleDrawer} = props
    const handleClick = (props) => {
        const name = props.key
        setDummy(!dummy)

        if (togStatus === true) toggleDrawer()
        if (name === 'sessions' && isPro)
            window.location.href = '/backend/sessionbridge';

        if (name === 'sessions' && !isPro)
            window.location.href = '/backoffice/shop';
        if (name === 'team-tree' && !isPro)
            window.location.href = '/backoffice/shop';

        else if (name === 'dashboard') history.push('/')
        else history.push({
                pathname: `/${name}`,
                state: {
                    pageName: props?.item?.props?.children[1]?.props?.children || props?.item?.props?.children[1]
                }
            })

    };

    async function isProCheck() {
        const {data} = await client.query({query: checkProStatus});
        if (data) {
            if (data?.me?.currentSalesPackage?.name === 'Professional') {
                setIntoLocalStorage('isPro', true);
            }


            setIntoLocalStorage('user', data?.me);
            user = data?.me
            // localStorage.setItem('isAdmin', user.user_firstname+user.user_lastname === 'WernerKaiser');
            setDummy(!dummy)
        }

    }

    useEffect(() => {
        async function fetchData() {
            if (!localStorage.getItem('isPro') || !Object.keys(user).length)
                await isProCheck();
        }

        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function isSelected(route) {
        return history.location.pathname === route
    }

    return (
        <Menu
            onClick={handleClick}
            style={{width: 190}}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['dashboard']}
            mode="inline"
            className='menu'
        >
            <div>
                <img id='menuLogo' className='logoImage' src='/backend/file/9fb11a82a' alt=""/>
            </div>
            <div className='profile-menu'>
                <img id='profilePictureMenu' className='profileImage'
                     // src={'/backend/user-file/profile-picture?time=' + new Date().getTime()} alt=""/>
                     src={user?.profile_picture_url ? '/backend/partner-file/'+user?.profile_picture_url : photo_placeholder} alt=""/>
                <div className='profile-info overflowHidden' title={user?.user_name}>{user?.user_name}</div>
                <div className='profile-info'>ID: {user?.id}</div>
                <div className='profile-divider'/>
            </div>

            {pageRoutes.map(e => {
                return <Menu.Item icon={e.icon} className={isSelected(e.route) ? "ant-menu-item-selected" : "ant-menu-item"}
                                  style={(e?.isProfessional && !isPro) ? {opacity: '.5'} : {}}
                                  key={e.key}>{t(e.text)}</Menu.Item>
            })}
        </Menu>
    )
}
export default LeftMenu
